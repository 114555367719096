
import { Component, Vue, Prop } from 'vue-property-decorator';


@Component({
    components: {
    }
})
export default class ClosingLevelFormItem extends Vue {
    @Prop()
    public value!: number;

    public onValueChange(newVal: number|null) {
        newVal = newVal || null;
        this.$emit('input', newVal);
    }
}

