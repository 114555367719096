
import { Component, Vue } from 'vue-property-decorator';
import auth from '@/apiClient/auth';

@Component({
    components: {
    }
})
export default class App extends Vue {

    public logout() {
        auth.logout();
        this.$router.push({
            name: 'login',
        });
    }
}
