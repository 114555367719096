
import { Component, Vue, Prop } from 'vue-property-decorator';
import ApiVendeur, { ApiVendeurCreate } from '@/apiClient/resources/vendeur/apiVendeur';
import Vendeur from '@/apiClient/resources/vendeur/vendeur';
import VendeurStatus from '@/modules/vendeurStatus';
import currencyFormatter from '@/modules/currencyFormatter';
import nextCallPickerOptions from '@/modules/nextCallPickerOptions';
import VendeurCategories from '@/modules/vendeurCategories';
import Acheteur from '@/apiClient/resources/acheteur/acheteur';
import ApiAcheteur from '@/apiClient/resources/acheteur/apiAcheteur';
import VendeurTypes from "@/modules/vendeurTypes";
import ClosingLevelFormItem from "@/components/sharedFormItems/ClosingLevelFormItem.vue";
import StepFormItem from "@/components/sharedFormItems/StepFormItem.vue";
import EstateCategoryFormItem from "@/components/sharedFormItems/EstateCategoryFormItem.vue";
import SalePriceFormItem from "@/components/sharedFormItems/SalePriceFormItem.vue";
import EtatLocatifFormItem from "@/components/sharedFormItems/EtatLocatifFormItem.vue";
import EmailsListEdit from "@/components/sharedFormItems/EmailsListEdit.vue";
import splitEmailString from "@/modules/splitEmailString";

@Component({
    components: {
        EstateCategoryFormItem,
        StepFormItem,
        CreateUpdateAcheteurForm: () => import('@/components/CreateUpdateAcheteurForm.vue'),
        ClosingLevelFormItem,
        SalePriceFormItem,
        EtatLocatifFormItem,
        EmailsListEdit,
    },
})
export default class CreateUpdateVendeurForm extends Vue {
    @Prop({ default: null })
    public showOnlyFields!: null|string[];

    public currencyFormatter = currencyFormatter;

    @Prop({ default: null })
    public vendeur!: Vendeur; // vendeur to edit, if any

    // create and update are the same
    public originalFormData: ApiVendeurCreate = {
        full_name: '',
        telephone: '',
        email: '',
        status: '',
        category: '',
        type: '',
        closing_level: 0,
        step: '',
        address: '',
        address2: '',
        // estate
        estate_address: '',
        estate_nb_flats: null,
        estate_category: '',
        estate_postcode: '',
        estate_nb_parking: null,
        estate_price: null,
        estate_etat_locatif: null,
        estate_construction_date: null,
        estate_renovation_date: null,
        // other
        next_call: '',
        next_action: '',
    }

    public errorsMap: any = null;

    public getError(errorKey: string) {
        if (this.errorsMap && this.errorsMap[errorKey] && Array.isArray(this.errorsMap[errorKey])) {
            return this.errorsMap[errorKey][0] || '';
        }
    }

    public form: ApiVendeurCreate = {
        ...this.originalFormData,
    };

    public linkedAcheteur: Acheteur|null = null;
    public addLinkedFormVisible = false;

    public statuses = VendeurStatus;
    public categories = VendeurCategories;
    public types = VendeurTypes;

    public pickerOptions = nextCallPickerOptions;

    public created() {
        this.resetForm();
    }

    public resetForm() {
        this.form = {
            ...this.originalFormData,
        };
        if (this.vendeur) {
            const seriVendeur = this.vendeur.serialize(Object.keys(this.form)) as ApiVendeurCreate;
            this.form = {
                ...seriVendeur,
            };
            this.linkedAcheteur = this.vendeur.linked ? Acheteur.deserialize(this.vendeur.linked.serialize() as ApiAcheteur) : null;
        }
    }

    public isFieldShown(fieldKey: string) {
        return !this.showOnlyFields || this.showOnlyFields.includes(fieldKey);
    }

    public onEmailChange(emails: string[]) {
        this.form.email = splitEmailString((emails || []).join(', ')).join(', ');
    }

    public createOrUpdateVendeur() {
        const payload: {[key: string]: any} = {
            ...this.form,
            linked_acheteur: this.linkedAcheteur?.id,
        };

        delete payload.notes;

        const isNullable = (key: string) => {
            return ['next_call', 'estate_nb_flats', 'estate_nb_parking', 'estate_price', 'estate_etat_locatif', 'estate_construction_date', 'estate_renovation_date'].includes(key);
        }

        for (const key in payload) {
            if (payload[key] === '' && isNullable(key)) {
                payload[key] = null;
            }
        }

        this.errorsMap = null;

        const isEdit = !!this.vendeur;

        const request = isEdit
            ? this.$api.vendeurs.patch.bind(this.$api.vendeurs) : this.$api.vendeurs.post.bind(this.$api.vendeurs);
        const uuid = isEdit ? this.vendeur.id : [];

        request(uuid as any, payload)
            .then((res) => {
                const word = isEdit ? 'modifié' : 'créé';
                this.$notify.success('Le vendeur a été ' + word + ' avec succès');
                this.$emit('success', res);
            })
            .catch((err) => {
                console.error(err);
                this.errorsMap = err.response?.data;
                this.$notify.error('Une erreur est survenue, merci de vérifier les champs du formulaire');
            });
    }

    public onSuccessCreateAcheteur(data: Acheteur) {
        this.linkedAcheteur = data || null;
    }

    public showLinkedForm() {
        this.addLinkedFormVisible = true;
    }

}
