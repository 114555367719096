
const VendeurCategories: {[key: string]: {key: string; label: string; background?: string; color?: string}} = {
    COMPANY: {
        key: 'company',
        label: 'Entreprise',
    },
    PRIVE_IMMEUBLE: {
        key: 'prive-immeuble',
        label: 'Privé immeuble',
    },
    PRIVE_MAISON: {
        key: 'prive-maison',
        label: 'Privé bien premium',
    },
};

export default VendeurCategories;
