import ListResult from '@/apiClient/queryHelpers/listResult';
import ListQuery from '@/apiClient/queryHelpers/listQuery';
import ApiServiceDeserialized from '@/apiClient/resources/apiServiceDeserialized';

function loadAllPages<T, ApiT, ApiTUpdate, ApiTCreate>(
    service: ApiServiceDeserialized<T, ApiT, ApiTUpdate, ApiTCreate>,
    listQuery?: Partial<ListQuery>,
    uuids?: string|string[],
    urlExtraParts: string[] = [],
    customMethod?: string): Promise<ListResult<T>> {

    const pageSize = 100;
    const newListQuery = new ListQuery(listQuery);

    const allObjects: T[] = [];

    return new Promise<ListResult<T>>((resolve, reject) => {

        const recursiveLoad = (offset: number) => {
            newListQuery.pager = {
                limit: pageSize,
                offset
            };

            service.list(newListQuery, uuids, urlExtraParts, customMethod)
                .then((listRes: ListResult<T>) => {
                    allObjects.push(...listRes.results);
                    if (listRes.hasNext) {
                        offset += pageSize;
                        recursiveLoad(offset);
                    } else {
                        resolve(new ListResult(allObjects, listRes.count));
                    }
                })
                .catch((error: any) => {
                    reject(error);
                });
        };

        recursiveLoad(0);
    });

}

export default loadAllPages;
