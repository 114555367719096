import axios from 'axios';
import apiSingleton from '@/apiClient/apiSingleton';
import store from '@/store';

interface LoginResponse {
    access: string;
    refresh: string;
}

interface RefreshResponse {
    access: string;
}

class Auth {
    public _token = '';
    public _refreshToken = '';
    public serverOrigin = process.env.VUE_APP_API_URL;

    get token() {
        return this._token;
    }

    set token(newToken: string) {
        this._token = newToken;
        localStorage.setItem(this.LS_TOKEN, newToken);
        apiSingleton.setToken(this.token);
    }

    get refreshToken() {
        return this._refreshToken;
    }

    set refreshToken(newToken: string) {
        this._refreshToken = newToken;
        localStorage.setItem(this.LS_REFRESH_TOKEN, newToken);
    }

    // localstorage keys
    public LS_TOKEN = 'API_ACCESS_TOKEN';
    public LS_REFRESH_TOKEN = 'API_REFRESH_TOKEN';

    constructor() {
        this.setTokensFromStorage();
    }

    public setTokensFromStorage() {
        this.token = localStorage.getItem(this.LS_TOKEN) || '';
        this.refreshToken = localStorage.getItem(this.LS_REFRESH_TOKEN) || '';
    }

    public login(username: string, password: string) {
        return axios.post<LoginResponse>(`${this.serverOrigin}/api/token/`, {
            username,
            password,
        })
            .then((result) => {
                const resp = result.data;
                this.token = resp.access;
                this.refreshToken = resp.refresh;

                apiSingleton.setToken(this.token);

                return resp;
            })
            .catch((err) => {
                this.token = '';
                this.refreshToken = '';
                throw err;
            });
    }

    public logout() {
        this.token = '';
        this.refreshToken = '';
        store.commit('setUserSelf', null);
    }

    public refreshOldToken(): Promise<string> {
        if (!this.refreshToken) {
            return Promise.reject(new Error('No refresh token'));
        }
        return axios.post<RefreshResponse>(`${this.serverOrigin}/api/token/refresh/`, {
            refresh: this.refreshToken,
        })
            .then((resp) => {
                this.token = resp.data.access;
                return this.token;
            })
            .catch((err) => {
                this.token = '';
                this.refreshToken = '';
                throw err;
            })
    }

}

export default new Auth();
