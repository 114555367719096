import pick from '@/apiClient/queryHelpers/pick';
import ApiVendeur from '@/apiClient/resources/vendeur/apiVendeur';
import VendeurStatus from '@/modules/vendeurStatus';
import CallNote from '@/apiClient/resources/callNote/callNote';
import ApiCallNote from '@/apiClient/resources/callNote/apiCallNote';
import telNumbersToLinks from '@/modules/telNumbersToLinks';
import { nextCallFormatted } from '@/modules/dateFormatter';
import VendeurCategories from '@/modules/vendeurCategories';
import Acheteur from '@/apiClient/resources/acheteur/acheteur';
import ApiAcheteur from '@/apiClient/resources/acheteur/apiAcheteur';
import VendeurTypes from "@/modules/vendeurTypes";
import Steps from "@/modules/steps";
import EstateCategories from "@/modules/estateCategories";
import currencyFormatter from "@/modules/currencyFormatter";

interface ContactInfo {
    fullName: string;
    telephone: string;
    email: string;
    address: string;
    address2: string;
}

interface EstateInfo {
    address: string|null;
    nbFlats: number|null;
    postcode: string|null;
    category: string|null;
    nbParking: number|null;
    price: number|null;
    etatLocatif: number|null;
    constructionDate: number|null;
    renovationDate?: number|null;
}

class Vendeur {
    public id!: string;
    public contact!: ContactInfo;
    public estate!: EstateInfo;
    public nextCall!: string;
    public nextAction!: string;
    public notes!: string;
    public calls: CallNote[] = [];
    public status!: string;
    public category!: string;
    public type!: string;
    public closingLevel!: number|null;
    public step!: string;
    public linked: Acheteur|null = null;
    public lineups: number[] = [];
    public onMailchimp = false;
    public starred = false;
    public errors = '';

    constructor(apiVendeur: ApiVendeur) {
        this.id = apiVendeur.id;
        this.status = apiVendeur.status;
        this.category = apiVendeur.category;
        this.type = apiVendeur.type;
        this.closingLevel = apiVendeur.closing_level;
        this.step = apiVendeur.step || '';
        this.errors = apiVendeur.errors;
        this.lineups = apiVendeur.lineups;
        this.onMailchimp = apiVendeur.on_mailchimp;
        this.starred = apiVendeur.starred;

        this.contact = {
            address: apiVendeur.address || '',
            address2: apiVendeur.address2 || '',
            email: apiVendeur.email || '',
            fullName: apiVendeur.full_name || '',
            telephone: apiVendeur.telephone || ''
        };
        this.estate = {
            address: apiVendeur.estate_address || '',
            nbFlats: apiVendeur.estate_nb_flats,
            postcode: apiVendeur.estate_postcode || '',
            category: apiVendeur.estate_category || '',
            nbParking: apiVendeur.estate_nb_parking,
            price: apiVendeur.estate_price,
            etatLocatif: apiVendeur.estate_etat_locatif,
            constructionDate: apiVendeur.estate_construction_date,
            renovationDate: apiVendeur.estate_renovation_date,
        };
        this.nextCall = apiVendeur.next_call;
        this.nextAction = apiVendeur.next_action;
        this.notes = apiVendeur.notes;

        this.calls = (apiVendeur.calls || []).map(CallNote.deserialize).sort((a, b) => {
            return a.date > b.date ? -1 : 1;
        });

        if (apiVendeur.linked) {
            this.linked = Acheteur.deserialize(apiVendeur.linked);
        }
    }

    get todaysCall() {
        const today = new Date();
        const firstCall = this.calls[0];
        if (firstCall && firstCall.date.toISOString().slice(0, 10) === today.toISOString().slice(0, 10)) {
            return firstCall;
        }
        return null;
    }

    get telephoneLinks() {
        return telNumbersToLinks(this.contact.telephone || '');
    }

    get nextCallDate() {
        if (!this.nextCall) {
            return null;
        }
        const d = new Date(this.nextCall);
        if (!isNaN(d as any)) {
            return d;
        }
        return null;
    }

    get nextCallLabel() {
        return nextCallFormatted(this.nextCallDate);
    }

    get statusInfo() {
        const foundStatus = Object.keys(VendeurStatus).find((key) => {
            return VendeurStatus[key].key === this.status;
        });
        if (foundStatus) {
            return VendeurStatus[foundStatus];
        }
        return null;
    }

    get statusLabel() {
        if (this.statusInfo) {
            return this.statusInfo.label;
        }
        return this.status;
    }

    get categoryInfo() {
        const foundStatus = Object.keys(VendeurCategories).find((key) => {
            return VendeurCategories[key].key === this.category;
        });
        if (foundStatus) {
            return VendeurCategories[foundStatus];
        }
        return null;
    }

    get categoryLabel() {
        if (this.categoryInfo) {
            return this.categoryInfo.label;
        }
        return this.category;
    }

    get typeInfo() {
        const foundType = Object.keys(VendeurTypes).find((key) => {
            return VendeurTypes[key].key === this.type;
        });
        if (foundType) {
            return VendeurTypes[foundType];
        }
        return null;
    }

    get typeLabel() {
        if (this.typeInfo) {
            return this.typeInfo.label;
        }
        return this.type;
    }

    get stepInfo() {
        const foundStep = Object.keys(Steps).find((key) => {
            return Steps[key].key === this.step;
        });
        if (foundStep) {
            return Steps[foundStep];
        }
        return null;
    }

    get stepLabel() {
        if (this.stepInfo) {
            return this.stepInfo.label;
        }
        return this.step;
    }

    get estateCategoryInfo() {
        const foundCat = Object.keys(EstateCategories).find((key) => {
            return EstateCategories[key].key === this.estate.category;
        });
        if (foundCat) {
            return EstateCategories[foundCat];
        }
        return null;
    }

    get estateCategoryLabel() {
        if (!this.estate.category) {
            return '';
        }
        if (this.estateCategoryInfo) {
            return this.estateCategoryInfo.label;
        }
        return this.estate.category;
    }

    get yieldDisplay() {
        if (!this.estate.price || !this.estate.etatLocatif) {
            return '';
        }
        return (100 * this.estate.etatLocatif / this.estate.price).toFixed(2) + '%';
    }

    get yieldTooltip() {
        if (!this.estate.price || !this.estate.etatLocatif) {
            return '';
        }
        return `${currencyFormatter(this.estate.etatLocatif)} / ${currencyFormatter(this.estate.price)}`;
    }

    public static deserialize(apiVendeur: ApiVendeur) {
        return new Vendeur(apiVendeur);
    }

    public serialize(fields?: string[]): Partial<ApiVendeur> {
        const apiVendeur: ApiVendeur = {
            id: this.id,
            full_name: this.contact.fullName,
            telephone: this.contact.telephone,
            email: this.contact.email,
            address: this.contact.address,
            address2: this.contact.address2,

            errors: this.errors,

            status: this.status,
            category: this.category,
            type: this.type,
            closing_level: this.closingLevel,
            step: this.step,
            starred: this.starred,

            estate_address: this.estate.address,
            estate_nb_flats: this.estate.nbFlats,
            estate_postcode: this.estate.postcode,
            estate_category: this.estate.category,
            estate_nb_parking: this.estate.nbParking,
            estate_price: this.estate.price,
            estate_etat_locatif: this.estate.etatLocatif,
            estate_construction_date: this.estate.constructionDate,
            estate_renovation_date: this.estate.renovationDate,

            next_call: this.nextCall,
            next_action: this.nextAction,
            notes: this.notes,

            calls: this.calls.map((c) => (c.serialize() as ApiCallNote)),

            linked: this.linked ? this.linked.serialize() as ApiAcheteur : null,

            lineups: this.lineups,
            on_mailchimp: this.onMailchimp,
        };

        if (fields) {
            return pick(apiVendeur, fields) as Partial<ApiVendeur>;
        }

        return apiVendeur;
    }

}
export default Vendeur;
