import ApiVendeur, { ApiVendeurCreate, ApiVendeurUpdate } from '@/apiClient/resources/vendeur/apiVendeur';
import ApiClient from '@/apiClient/core/apiClient';
import ApiServiceDeserialized from '@/apiClient/resources/apiServiceDeserialized';
import Vendeur from '@/apiClient/resources/vendeur/vendeur';
import ListQuery from '@/apiClient/queryHelpers/listQuery';
import buildQueryConfig from '@/apiClient/queryHelpers/buildQueryConfig';
import ListResult from "@/apiClient/queryHelpers/listResult";

class VendeurService
    extends ApiServiceDeserialized<
        Vendeur,
        ApiVendeur,
        ApiVendeurUpdate,
        ApiVendeurCreate> {

    public static readonly ENDPOINTS = ['vendeurs'];

    constructor(client: ApiClient) {
        super(client, VendeurService.ENDPOINTS, Vendeur.deserialize);
    }

    public loadCSV(listQ: ListQuery) {
        const queryConfig = buildQueryConfig(
            this.createUrlParts(undefined, this.ENDPOINTS),
            listQ,
            'csv');
        this.client.apiCore.get(
            `${process.env.VUE_APP_API_URL}/api/v1/${this.client.buildUrl(queryConfig)}`,
            {
                responseType: 'blob',
                headers: {
                    //'Accept': 'text/csv'
                }
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data as any]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'vendeurs.csv');
                document.body.appendChild(link);
                link.click();
            })
    }

}

export default VendeurService;
