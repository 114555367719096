
import { Component, Vue, Prop } from 'vue-property-decorator';
import Vendeur from '@/apiClient/resources/vendeur/vendeur';
import AddEditCallForm from '@/components/AddEditCallForm.vue';
import Acheteur from '@/apiClient/resources/acheteur/acheteur';
import CallNote from '@/apiClient/resources/callNote/callNote';

@Component({
    components: {
        AddEditCallForm,
    }
})
export default class CallsList extends Vue {

    @Prop({ default: null })
    public user!: Vendeur|Acheteur;

    @Prop({ default: 'vendeur' })
    public userType!: string;

    public dialogCallEditVisible = false;

    public selectedCallForEdit: CallNote|null = null;

    get callsList(): CallNote[] {
        return this.user?.calls || [];
    }

    public onEditSuccess(callUpdated: CallNote) {
        const call = this.callsList.find((c) => {
            return c.id === callUpdated.id;
        });
        if (call) {
            call.answered = callUpdated.answered;
            call.notes = callUpdated.notes;
            call.date = callUpdated.date;
        }
        this.dialogCallEditVisible = false;
    }

    public editCall(call: CallNote) {
        this.selectedCallForEdit = call;
        this.dialogCallEditVisible = true;
    }

    public removeCall(call: CallNote) {
        this.$api.callNotes.delete(call.id.toString())
            .then((resp) => {
                this.user.calls = this.user.calls.filter((c) => {
                    return c.id !== call.id;
                });
            })
            .catch((err) => {
                console.error(err);
                this.$notify.error('L\'appel n\'a pas pu être supprimé, merci de réessayer');
            });
    }

}
