
const nextCallFormatted = (d?: Date|null) => {
    if (!d) {
        return '–';
    }
    let m: string|number = d.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m.toString();
    const y = d.getFullYear() === new Date().getFullYear() ? '' : ('.' + d.getFullYear());
    const min = d.getMinutes() < 10 ? ('0' + d.getMinutes()) : d.getMinutes().toString();

    return `${d.getDate()}.${m}${y} ${d.getHours()}h${min}`;
};

export {
    nextCallFormatted,
};
