
const EstateCategories: {[key: string]: {key: string; label: string }} = {
    LOCATIF: {
        key: 'locatif',
        label: 'Locatif',
    },
    MIXT: {
        key: 'mixt',
        label: 'Mixte',
    },
};

export default EstateCategories;
