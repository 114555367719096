
import { Component, Vue, Prop } from 'vue-property-decorator';
import VendeurStatus from '@/modules/vendeurStatus';
import Vendeur from '@/apiClient/resources/vendeur/vendeur';

@Component({
    components: {
    }
})
export default class VendeurStatusLabel extends Vue {

    @Prop({ default: null })
    public vendeur!: Vendeur;

    get background() {
        return this.vendeur.statusInfo?.background || 'white';
    }

    get color() {
        return this.vendeur.statusInfo?.color || '#555';
    }
}
