import { Method } from 'axios';

import ApiClient from '@/apiClient/core/apiClient';
import ApiService from '@/apiClient/core/apiService';
import ApiListResult from '@/apiClient/queryHelpers/apiListResult';
import buildQueryConfig from '@/apiClient/queryHelpers/buildQueryConfig';
import ListQuery from '@/apiClient/queryHelpers/listQuery';
import ListResult from '@/apiClient/queryHelpers/listResult';
import loadAllPages from '@/apiClient/queryHelpers/loadAllPages';

/**
 * Superclass to simplify the creation of a fully usable service with deserialization for the ARTPI
 */
abstract class ApiServiceDeserialized<DeserializedT, ApiT, ApiTUpdate, ApiTCreate>
    extends ApiService<ApiT, ApiListResult<ApiT>, ApiTUpdate, ApiTCreate> {

    public ENDPOINTS: string[];
    public deserialize: (obj: ApiT) => DeserializedT;

    public constructor(client: ApiClient,
        endPoints: string|string[],
        deserializer: (obj: ApiT) => DeserializedT) {
        super(client);
        if (Array.isArray(endPoints)) {
            this.ENDPOINTS = endPoints;
        } else {
            this.ENDPOINTS = [endPoints];
        }
        this.deserialize = deserializer;
    }

    protected createUrlParts(ids: string|string[] = [], endpoints: string[] = this.ENDPOINTS) {
        ids = typeof ids === 'string' ? [ids] : ids;
        const urlParts: string[] = [];
        endpoints.forEach((e: string, i: number) => {
            urlParts.push(e);
            if (typeof ids[i] !== 'undefined') {
                urlParts.push(ids[i]);
            }
        });
        urlParts[urlParts.length - 1] += '/';
        return urlParts;
    }

    public get(uuids: string|string[], customMethod?: string): Promise<DeserializedT> {
        const queryConfig = buildQueryConfig(
            this.createUrlParts(uuids),
            undefined,
            customMethod);

        return this._get(queryConfig)
            .then((apiObj: ApiT) => {
                return this.deserialize(apiObj);
            });
    }

    public list(
        listQuery?: ListQuery,
        uuids?: string|string[],
        urlExtraParts: string[] = [],
        customMethod?: string): Promise<ListResult<DeserializedT>> {

        const queryConfig = buildQueryConfig(
            [...this.createUrlParts(uuids), ...urlExtraParts],
            listQuery,
            customMethod);

        return this._list(queryConfig)
            .then((apiListResult: ApiListResult<ApiT>) => {
                return new ListResult<DeserializedT>(
                    apiListResult.results.map(this.deserialize),
                    apiListResult.count,
                    apiListResult.previous,
                    apiListResult.next);
            });
    }

    public listAll(listQuery?: ListQuery,
        uuids?: string|string[],
        urlExtraParts: string[] = [],
        customMethod?: string): Promise<ListResult<DeserializedT>> {
        return loadAllPages<DeserializedT, ApiT, ApiTUpdate, ApiTCreate>(
            this,
            listQuery,
            uuids,
            urlExtraParts,
            customMethod
        );
    }

    public patch(uuids: string|string[], apiUpdateData: ApiTUpdate): Promise<DeserializedT> {
        const queryConfig = buildQueryConfig(this.createUrlParts(uuids));
        return this._patch(queryConfig, apiUpdateData)
            .then((apiObj: ApiT) => {
                return this.deserialize(apiObj);
            });
    }

    public post(uuids: string|string[] = [], apiCreateData: ApiTCreate): Promise<DeserializedT> {
        const queryConfig = buildQueryConfig(this.createUrlParts(uuids));
        return this._post(queryConfig, apiCreateData)
            .then((apiObj: ApiT) => {
                return this.deserialize(apiObj);
            });
    }

    public delete(uuids: string|string[] = []): Promise<any> {
        const queryConfig = buildQueryConfig(this.createUrlParts(uuids));
        return this._delete(queryConfig);
    }

    public custom<D = any, R = any>(config: {
        method: Method;
        customMethod?: string;
        listQuery?: ListQuery;
        endPoints?: string[];
        uuids: string|string[];
        data?: D;
    }): Promise<R> {
        const queryConfig = buildQueryConfig(
            this.createUrlParts(config.uuids, config.endPoints),
            config.listQuery,
            config.customMethod);
        return this._request<D, R>(config.method, queryConfig, config.data);
    }

}

export default ApiServiceDeserialized;
