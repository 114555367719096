import Vue from 'vue';
import Vuex from 'vuex';
import User from '@/apiClient/resources/user/user';

Vue.use(Vuex);

interface StoreState {
  user: null|User;
}

const storeOptions: { state: StoreState; mutations: any; actions: any ; getters: any} = {
    state: {
        user: null,
    },
    mutations: {
        setUserSelf(state: StoreState, user: User) {
            state.user = user;
        }
    },
    actions: {},
    getters: {
        isAdmin(state: StoreState) {
            return state?.user?.isSuperuser;
        },
        userId(state: StoreState) {
            return state?.user?.id || null;
        }
    },
}

export default new Vuex.Store(storeOptions);
