const oneDay = 3600 * 1000 * 24;

const nextCallPickerOptions = {
    firstDayOfWeek: 1,
    shortcuts: [{
        text: 'Aujourd\'hui',
        onClick(picker: any) {
            const date = new Date();
            date.setHours(11, 0);
            picker.$emit('pick', date);
        }
    }, {
        text: 'Demain',
        onClick: (picker: any) => {
            const date = new Date();
            date.setTime(date.getTime() + oneDay);
            date.setHours(11, 0);
            picker.$emit('pick', date);
        }
    }, {
        text: 'Dans 2 jours',
        onClick: (picker: any) => {
            const date = new Date();
            date.setTime(date.getTime() + oneDay * 2);
            date.setHours(11, 0);
            picker.$emit('pick', date);
        }
    },
    ]
};

export default nextCallPickerOptions;
