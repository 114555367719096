
import { Component, Vue, Watch } from 'vue-property-decorator';
import XLSX from 'xlsx';
import { ApiVendeurCreate } from '@/apiClient/resources/vendeur/apiVendeur';
import VendeurStatus from '@/modules/vendeurStatus';
import { ApiCallNoteCreate, ApiCallNoteUpdate } from '@/apiClient/resources/callNote/apiCallNote';
import callsSplitter from '@/modules/callsSplitter';
import VendeurCategories from '@/modules/vendeurCategories';

interface VendeurToCreate extends ApiVendeurCreate {
    calls: (ApiCallNoteUpdate|ApiCallNoteCreate)[];
}

const make_cols = (refstr: any) => Array(XLSX.utils.decode_range(refstr).e.c + 1).fill(0)
    .map((x, i) => ({ name: XLSX.utils.encode_col(i), key: i }));

@Component({
    components: {
    }
})
export default class ImportCsvVendeurs extends Vue {

    public loading = false;
    public vendeursToCreate: VendeurToCreate[] = [];
    public createdIndexes: number[] = []; // already created

    public statuses = VendeurStatus;
    public categories = VendeurCategories;
    public globalStatus = '';
    public globalCategory = '';
    public globalNextCall = '';
    public globalAction = '';

    @Watch('globalStatus')
    public onGlobalStatusChange() {
        if (this.globalStatus) {
            this.vendeursToCreate.forEach((v) => {
                v.status = this.globalStatus;
            });
        }
    }

    public validateGlobalAction() {
        this.vendeursToCreate.forEach((v) => {
            v.next_action = this.globalAction;
        });
    }

    @Watch('globalCategory')
    public onGlobalCategoryChange() {
        if (this.globalCategory) {
            this.vendeursToCreate.forEach((v) => {
                v.category = this.globalCategory;
            });
        }
    }

    @Watch('globalNextCall')
    public onGlobalNextCallChange() {
        const nextCall = this.globalNextCall ? this.globalNextCall : '';
        this.vendeursToCreate.forEach((v) => {
            v.next_call = nextCall;
        });
    }

    public createVendeurs() {
        this.loading = true;
        const allPromises: Promise<any>[] = this.vendeursToCreate.map((vendeurData, i) => {
            const calls = vendeurData.calls;
            delete vendeurData.calls;

            if (vendeurData.next_call) {
                const nC = new Date(vendeurData.next_call);
                nC.setHours(14);
                vendeurData.next_call = nC.toISOString();
            } else if (vendeurData.next_call === '') {
                delete vendeurData.next_call;
            }

            return this.$api.vendeurs.post([], vendeurData)
                .then((newVendeur) => {
                    this.createdIndexes.push(i);
                    // Create "calls"
                    if (calls && calls.length > 0) {
                        const callsToCreate: ApiCallNoteCreate[] = calls.map((newCall) => {
                            try {
                                newCall.date = new Date(newCall.date).toISOString();
                            } catch (err) {
                                console.log('Bad date', newCall.date, newCall.notes);
                                newCall.date = new Date('2018-01-01').toISOString();
                            }

                            return {
                                ...newCall,
                                object_id: newVendeur.id,
                                content_type: 6, // vendeur
                            }
                        });

                        return this.$api.callNotes.post([], callsToCreate).then(() => {
                            return null;
                        });
                    } else {
                        return Promise.resolve();
                    }
                });
        });

        Promise.all(allPromises).then((res) => {
            this.loading = false;
            this.$notify.success('Les éléments ont été ajoutés avec succès');
            this.vendeursToCreate = [];
        });
    }

    public deleteRow(rowI: number) {
        this.vendeursToCreate.splice(rowI, 1);
    }

    public onFileChange(e: any) {
        /* Boilerplate to set up FileReader */
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target?.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            let data = XLSX.utils.sheet_to_json(ws, { header: 1 });

            data = data.filter((row: any) => {
                return !!row && row.length > 0;
            })

            // console.log('Data', data);
            this.vendeursToCreate = data.map((row: any) => {
                const callsArray: ApiCallNoteCreate[] = callsSplitter(row[3]);

                return {
                    full_name: row[1],
                    telephone: row[2],
                    email: row[7],
                    status: '',
                    category: '',
                    address: `${row[4] || ''} ${row[5] || ''} ${row[6] || ''}`,

                    estate_address: row[0],
                    estate_nb_flats: null,
                    estate_postcode: '',
                    estate_category: '',
                    estate_nb_parking: null,
                    estate_price: null,
                    estate_etat_locatif: null,
                    estate_construction_date: null,
                    estate_renovation_date: null,

                    // Other
                    notes: '',
                    next_call: '',
                    next_action: '',

                    calls: callsArray,
                }
            });

            // console.log(data, make_cols(ws['!ref']))
        };
        reader.readAsBinaryString(file);
    }

    public removeNote(vendeur: VendeurToCreate, noteIndex: number) {
        vendeur.calls.splice(noteIndex, 1);
    }

}
