import ListQuery from '@/apiClient/queryHelpers/listQuery';
import { CoreQueryConfig } from '@/apiClient/core/apiClient';

/**
 *
 * @param {Array<string>} urlParts
 * @param {ListQuery=} listQuery
 * @param {string=} customMethod
 */
const buildQueryConfig = (urlParts: string[], listQuery?: ListQuery, customMethod?: string): CoreQueryConfig => {
    const queryConfig: CoreQueryConfig = {
        urlParts
    };
    if (listQuery) {
        queryConfig.queryParams = new ListQuery(listQuery).getQueryParams();
    }
    if (customMethod) {
        queryConfig.customMethod = customMethod;
    }

    return queryConfig;
};

export default buildQueryConfig;
