
const callsSplitter = (callStr: string) => {

    if (!callStr) {
        return [];
    }

    return callStr.split('/').map((note: string) => {
        note = note.trim();
        let [day, month, year2] = note.split(' ').slice(0, 3);
        let failedDateParse = false;
        if (!parseInt(day) || day.length > 2) {
            day = '01';
            failedDateParse = true;
        }
        if (!parseInt(month) || month.length > 2) {
            month = '01';
            failedDateParse = true;
        }
        let year4 = '20' + year2;
        if (!parseInt(year2) || year2.length > 2) {
            year4 = '1970';
            failedDateParse = true;
        }

        const indexToSlice = failedDateParse ? 0 : 3;
        const noteText = note.split(' ').slice(indexToSlice).join(' ');
        const month2 = month.length === 2 ? month : ('0' + month);
        const day2 = day.length === 2 ? day : ('0' + day);
        const dateStr = `${year4}-${month2}-${day2}T14:00:00.000000Z`;

        return {
            date: dateStr,
            notes: noteText,
            answered: noteText.length > 0,
            object_id: '0', // set later
            content_type: -1, // set later
        };

    });
};

export default callsSplitter;
