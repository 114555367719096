import ApiCore, { RawResponse } from './apiCore';
import ApiConfiguration from './apiConfiguration';
import Header from './apiHeader';
import { Method } from 'axios';
import { KeyValue } from './keyValue';

type QueryParams = Array<KeyValue<string, string>>;

interface CoreQueryConfig {
    urlParts: string[];
    customMethod?: string;
    queryParams?: QueryParams;
    customAxiosConfig?: any;
}

class ApiClient {

    public apiCore = new ApiCore();

    get root() {
        return this.apiCore.root;
    }

    /**
     *
     * @param {ApiConfiguration} apiConfig
     */
    constructor(apiConfig: ApiConfiguration) {
        if (apiConfig) {
            this.configure(apiConfig);
        }
    }

    public configure(apiConfig: ApiConfiguration) {
        this.apiCore.configure(apiConfig);
    }

    public setHeader(header: Header) {
        this.apiCore.setHeader(header);
    }

    public setHeaders(headers: Header[]) {
        this.apiCore.setHeaders(headers);
    }

    public buildUrl(coreConfig: CoreQueryConfig) {
        const queryParams = coreConfig.queryParams || [];
        const queryParamsStr = queryParams.map((q) => {
            return `${q.key}=${q.value}`;
        }).join('&');
        const customMethod = coreConfig.customMethod ? coreConfig.customMethod + '/' : '';

        return coreConfig.urlParts.join('/') +
            customMethod +
            (queryParamsStr ? `?${queryParamsStr}` : '');
    }

    public get<R>(coreConfig: CoreQueryConfig): Promise<R> {
        const url = this.buildUrl(coreConfig);
        return this.apiCore.get<R>(url)
            .then((r: RawResponse<R>) => {
                return r.data;
            });
    }

    public post<D, R>(coreConfig: CoreQueryConfig, data: D): Promise<R> {
        const url = this.buildUrl(coreConfig);
        return this.apiCore.post<D, R>(url, data, coreConfig.customAxiosConfig)
            .then((r: RawResponse<R>) => {
                return r.data;
            });
    }

    public patch<D, R>(coreConfig: CoreQueryConfig, data: D): Promise<R> {
        const url = this.buildUrl(coreConfig);
        return this.apiCore.patch<D, R>(url, data)
            .then((r: RawResponse<R>) => {
                return r.data;
            });
    }

    public delete<R>(coreConfig: CoreQueryConfig): Promise<R> {
        const url = this.buildUrl(coreConfig);
        return this.apiCore.delete<R>(url)
            .then((r: RawResponse<R>) => {
                return r.data;
            });
    }

    public request<D, R>(method: Method, coreConfig: CoreQueryConfig, data?: D): Promise<R> {
        const url = this.buildUrl(coreConfig);
        return this.apiCore.request<D, R>(method, url, data)
            .then((r: RawResponse<R>) => {
                return r.data;
            });
    }

}

export default ApiClient;
export {
    CoreQueryConfig,
    QueryParams
};
