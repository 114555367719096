
import { Component, Vue } from 'vue-property-decorator';
import auth from '@/apiClient/auth';

    @Component({
        components: {
        }
    })
export default class Login extends Vue {

        public username = '';
        public password = '';
        public showError = false;

        public onInputChange() {
            this.showError = false;
        }

        public login() {
            if (!this.username || !this.password) {
                this.showError = true;
                return;
            }
            auth.login(this.username, this.password)
                .then((resp) => {
                    if (this.$route.query.next) {
                        this.$router.push(this.$route.query.next as string);
                    } else {
                        this.$router.push('/');
                    }
                })
                .catch((err: any) => {
                    this.showError = true;
                });
        }

}
