
import { Component, Vue, Watch } from 'vue-property-decorator';
import Acheteur from '@/apiClient/resources/acheteur/acheteur';
import ListQuery from '@/apiClient/queryHelpers/listQuery';
import CreateUpdateAcheteurForm from '@/components/CreateUpdateAcheteurForm.vue';
import AcheteurTypeLabel from '@/components/AcheteurTypeLabel.vue';
import Navbar from '@/components/Navbar.vue';
import AcheteurTypes from '@/modules/acheteurTypes';
import Pager from '@/apiClient/queryHelpers/pager';
import CallsDialog from '@/components/CallsDialog.vue';
import SendToMailchimpBtn from "@/components/SendToMailchimpBtn.vue";
import splitEmailString from "@/modules/splitEmailString";

@Component({
    components: {
        CreateUpdateAcheteurForm,
        AcheteurTypeLabel,
        Navbar,
        CallsDialog,
        SendToMailchimpBtn,
    }
})
export default class Acheteurs extends Vue {
    public $refs!: {
        callsDialog: any;
    };

    public splitEmailString = splitEmailString;
    public dialogCreateEditVisible = false;

    public loadingAcheteurs = true;
    public selectedAcheteur: Acheteur|null = null;

    public acheteurs: Acheteur[] = [];
    public acheteurTypes = AcheteurTypes;

    public showOnlyFieldsInForm: null|string[] = null;

    public nbPeopleToCall = 0;

    public filters: {[key: string]: any} = {
        type: '',
        price: 0,
        rendement__lte: 0,
        next_call: '',
        region: '',
        hide_null_next_call: false,
        next_action: '',
        has_email: '',
        errors: 'all',
    };

    // global search
    public searchFilter = '';

    public sortKey = 'next_call';
    public sortDirection = '';

    public acheteursCount = 0;
    public currentPage = 0;
    public pager: Pager = {
        limit: 12,
        offset: 0,
    }

    get isSmallScreen() {
        return ['mobile'].includes(this.$mq);
    }

    get showMailchimpBtn() {
        return this.$route.query.mailchimp === '1';
    }

    public mounted() {
        this.setFiltersFromQuery();
        this.loadAcheteurs();
        this.loadAcheteursToCall();
    }

    public rowClassName(data: {row: Acheteur; rowIndex: number}) {
        const acheteur = data.row;
        if (acheteur.todaysCall && acheteur.todaysCall.answered) {
            return 'called-today-with-answer';
        } else if (acheteur.todaysCall && !acheteur.todaysCall.answered) {
            return 'called-today-without-answer';
        }
        return '';
    }

    public onPageChange(index: number) {
        this.pager.offset = (index - 1) * this.pager.limit;
        this.loadAcheteurs();
        this.refreshQuery();
    }

    public resetPagination() {
        this.pager.offset = 0;
        this.currentPage = 1;
    }

    public resetFilters(reload = true) {
        this.searchFilter = '';
        const filters = {
            ...this.filters,
        };

        filters.price = null;
        filters.rendement__lte = null;
        filters.type = '';
        filters.next_call = '';
        filters.next_action = '';
        filters.region = '';
        filters.hide_null_next_call = false;
        filters.id = '';
        filters.has_email = '';
        filters.errors = 'all';

        this.filters = filters;
        this.onFiltersChange(reload);
    }

    public onFiltersChange(reload = true) {
        if (this.filters.errors === 'duplicates_telephone') {
            this.sortKey = 'telephone';
            this.sortDirection = '';
        } else if (this.filters.errors === 'duplicates_name') {
            this.sortKey = 'full_name';
            this.sortDirection = '';
        }
        this.resetPagination();
        this.refreshQuery();
        if (reload) {
            this.loadAcheteurs();
        }
    }

    public resetSearch() {
        this.searchFilter = '';
        this.onSearchChange();
    }

    public onSearchChange() {
        this.resetPagination();
        this.refreshQuery();
        this.loadAcheteurs();
    }

    public setFiltersFromQuery() {
        const query = this.$route.query;
        this.filters.price = query.price;
        this.filters.type = query.type || '';
        this.filters.next_action = query.next_action || '';
        this.filters.next_call = query.next_call || '';
        this.filters.rendement__lte = query.rendement__lte;
        this.filters.region = query.region;
        this.filters.hide_null_next_call = query.hide_null_next_call === 'true';
        this.filters.id = query.id;
        this.filters.has_email = query.has_email || '';
        this.filters.errors = query.errors;

        if (this.filters.id) {
            this.$notify.info({
                title: '',
                message: 'Vous avez lancé une recherche sur une personne particulière.' +
                    ' Pour retourner à la recherche normale, cliquez sur "Supprimer les filtres"',
                duration: 10000,
            });
        }

        this.searchFilter = query.search as string || '';
        if (query.page) {
            this.pager.offset = (Number(query.page) - 1) * this.pager.limit;
            this.currentPage = Number(query.page);
        }
        if (!this.currentPage) {
            this.currentPage = 1;
        }
        if (query.sort) {
            this.sortKey = (query.sort as string) || '';
        }
        if (query.sort_direction) {
            this.sortDirection = query.sort_direction as string || '';
        }

    }

    public refreshQuery() {
        this.$router.replace({
            name: this.$route.name as string,
            query: {
                ...this.filters,
                search: this.searchFilter,
                page: String(this.currentPage),
                sort: this.sortKey,
                sort_direction: this.sortDirection,
            },
        }).catch(() => {});
    }

    public loadCSV() {
        const listQ = this.getListQuery();
        // listQ.filters.errors_only = '1';
        return this.$api.acheteurs.loadCSV(listQ);
    }

    public getListQuery() {
        const filters = {
            ...this.filters,
        };
        if (filters.price <= 0) {
            delete filters.price;
        }
        if (filters.rendement__lte) {
            filters.rendement__lte = filters.rendement__lte.replace(',', '.');
            filters.rendement__lte = parseFloat(filters.rendement__lte) || 0;
            filters.rendement__gt = 0;
        } else {
            delete filters.rendement__lte;
            delete filters.rendement__gt;
        }
        for (const key in filters) {
            if (filters[key] === '') {
                delete filters[key];
            }
        }

        if (!filters.hide_null_next_call) {
            delete filters.hide_null_next_call;
        }

        if (filters.has_email === '') {
            delete filters.has_email;
        } else if (filters.has_email === '0') {
            filters.email_is_empty = 'true';
        } else if (filters.has_email === '1') {
            filters.email_is_empty = 'false';
        }

        const isPhoneSearch = this.searchFilter.slice(0, 3).length === 3 && !isNaN(this.searchFilter.slice(0, 3) as any);
        if (isPhoneSearch) {
            filters.telephone = this.searchFilter;
        }

        if (filters.errors === 'all') {
            delete filters.errors;
        } else if (filters.errors === '0') {
            filters.errors_is_empty = 'true';
        } else if (filters.errors === '1') {
            filters.errors_is_empty = 'false';
        } else if (filters.errors === 'duplicates_name') {
            filters.duplicates = 'full_name';
        } else if (filters.errors === 'duplicates_telephone') {
            filters.duplicates = 'telephone';
        }

        delete filters.has_email;
        delete filters.errors;

        return new ListQuery({
            filters,
            search: isPhoneSearch ? '' : this.searchFilter,
            pager: this.pager,
            sort: this.sortDirection + this.sortKey,
        });
    }

    public loadAcheteurs() {
        this.loadingAcheteurs = true;
        const listQ = this.getListQuery();
        this.$api.acheteurs.list(listQ)
            .then((res) => {
                this.acheteursCount = res.count || 0;
                this.acheteurs = res.results;
            })
            .catch((err) => {
                console.error(err);
            })
            .then(() => {
                this.loadingAcheteurs = false;
            });

    }

    public loadAcheteursToCall() {
        const listQ = new ListQuery({
            filters: {
                next_call: (new Date()).toISOString().slice(0, 10),
            },
            pager: {
                limit: 1,
                offset: 0,
            },
        });
        this.$api.acheteurs.list(listQ)
            .then((resp) => {
                this.nbPeopleToCall = resp.count || 0;
            })
            .catch((err) => {
            });
    }

    public showPeopleToCall() {
        this.resetFilters(false);
        this.filters.next_call = (new Date()).toISOString().slice(0, 10);
        this.onFiltersChange();
    }

    public showCreateForm() {
        this.showOnlyFieldsInForm = null;
        this.selectedAcheteur = null;
        this.dialogCreateEditVisible = true;
    }

    public onSuccessCreateOrUpdate() {
        this.loadAcheteurs();
        this.dialogCreateEditVisible = false;
        this.loadAcheteursToCall();
    }

    public editAcheteur(acheteur: Acheteur, fieldKey?: string) {
        this.showOnlyFieldsInForm = fieldKey ? [fieldKey] : null;
        this.selectedAcheteur = acheteur;
        this.dialogCreateEditVisible = true;
    }

    public deleteAcheteur(acheteur: Acheteur) {
        this.$confirm(`Confirmer la suppression de ${acheteur.contact.companyName}`,
            'Merci de confirmer', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Annuler',
                type: 'warning'
            }).then(() => {

            this.$api.acheteurs.delete(acheteur.id)
                .then((res) => {
                    this.$notify.success('Acheteur supprimé avec succès');
                    this.loadAcheteurs();
                })
                .catch((err) => {
                    this.$notify.error('Le acheteur n\'a pas pu être supprimé, merci de réessayer');
                });

        }).catch(() => {
            // nothing
        });
    }

    public onSuccessCallCreate() {
        this.loadAcheteurs(); // TODO optimiuze
    }

    public showCallsList(acheteur: Acheteur) {
        this.$refs.callsDialog.showCallsList(acheteur);
    }

    public addNewCall(acheteur: Acheteur) {
        this.$refs.callsDialog.addNewCall(acheteur);
    }

}
