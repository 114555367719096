
import { Component, Vue, Prop } from 'vue-property-decorator';
import EstateCategories from '@/modules/estateCategories';


@Component({
    components: {
    }
})
export default class EstateCategoryFormItem extends Vue {
    @Prop()
    public value!: string;

    public EstateCategories = EstateCategories;

    public onValueChange(newVal: string|null) {
        newVal = newVal || '';
        this.$emit('input', newVal);
    }
}

