
import { Component, Prop, Vue } from 'vue-property-decorator';
import splitEmailString from "@/modules/splitEmailString";

@Component({
    components: {
    }
})
export default class EmailsListEdit extends Vue {
    @Prop({default: ""})
    public emailsString!: null | string;

    public extraCountKey = 0;

    public created() {
        this.emails = splitEmailString(this.emailsString);
        if (this.emails.length === 0) {
            this.emails.push("");
        }
    }

    public emails: string[] = [];

    public onEmailChange(index: number, newValue: any) {
        const newEmails = [...this.emails];
        newEmails[index] = newValue;
        this.emails = newEmails;
        this.$emit("input", this.emails);
    }

    public addEmail() {
        this.emails.push("");
    }

    public deleteEmail(index: number) {
        this.emails = this.emails.filter((email, i) => i !== index);
        this.$emit("input", this.emails);
        this.extraCountKey += 20;
    }

}
