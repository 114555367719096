
import { Component, Vue, Prop } from 'vue-property-decorator';
import Vendeur from '@/apiClient/resources/vendeur/vendeur';

@Component({
    components: {
    }
})
export default class TypeLabel extends Vue {

    @Prop({ default: null })
    public vendeur!: Vendeur;

}
