import ApiClient from "@/apiClient/core/apiClient";
import ListQuery from "@/apiClient/queryHelpers/listQuery";

class MailchimpService {
    public apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    public createAudience() {
        return this.apiClient.apiCore.post(`${this.apiClient.root}/mailchimp/create_audience/`);
    }

    public addToAudience(listQuery: ListQuery|null = null, ficheType: 'vendeurs'|'acheteurs') {
        const queryParams = listQuery?.getQueryParams() || [];
        const queryParamsStr = queryParams.map((q) => {
            return `${q.key}=${q.value}`;
        }).join('&');
        return this.apiClient.apiCore.post(
            `${this.apiClient.root}/mailchimp/add_to_audience/?${queryParamsStr}`,
            {
                ficheType,
            }
        );
    }

}

export default MailchimpService;
