import ApiConfiguration from './apiConfiguration';
import Header from './apiHeader';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios';

declare type RawResponse<R> = AxiosResponse<R>;
declare type RawError = AxiosResponse;

class ApiCore {

    public root = '';

    public http = axios.create();

    /**
     * @param {ApiConfiguration} configuration
     */
    public configure(configuration: ApiConfiguration): ApiCore {
        if (configuration.root) {
            this.root = configuration.root;
        }

        this.http = axios.create({
            baseURL: this.root as string
        });
        return this;
    }

    public setHeader(header: Header): ApiCore {
        this.http.defaults.headers.common[header.key] = header.value;
        return this;
    }

    public setHeaders(headers: Header[]): ApiCore {
        headers.forEach((header: Header) => {
            this.setHeader(header);
        });
        return this;
    }

    public get<R>(url: string,
        customAxiosConfig?: Partial<AxiosRequestConfig>): Promise<AxiosResponse<R>> {
        return this.request<any, R>('get', url, null, customAxiosConfig);
    }

    public post<D, R>(url: string,
        data?: D,
        customAxiosConfig?: Partial<AxiosRequestConfig>): Promise<AxiosResponse<R>> {
        return this.request<D, R>('post', url, data, customAxiosConfig);
    }

    public patch<D, R>(url: string,
        data?: D,
        customAxiosConfig?: Partial<AxiosRequestConfig>): Promise<AxiosResponse<R>> {
        return this.request<D, R>('patch', url, data, customAxiosConfig);
    }

    public delete<R>(url: string,
        customAxiosConfig?: Partial<AxiosRequestConfig>): Promise<AxiosResponse<R>> {
        return this.request<any, R>('delete', url, customAxiosConfig);
    }

    /**
     *
     * @param {string} method
     * @param {string} url
     * @param {Object=} data
     * @param {AxiosRequestConfig=} customAxiosConfig
     * @return Promise<AxiosResponse<R>>
     */
    public request<D = any, R = any>(
        method: Method,
        url: string,
        data?: D,
        customAxiosConfig?: Partial<AxiosRequestConfig>): Promise<AxiosResponse<R>> {

        return new Promise<AxiosResponse<R>>((resolve, reject) => {
            customAxiosConfig = customAxiosConfig || {};

            const requestConfig: AxiosRequestConfig = {
                ...customAxiosConfig,
                method,
                url,
                data
            };
            this.http.request(
                requestConfig)
                .then((res: AxiosResponse<R>) => {
                    resolve(res as AxiosResponse<R>);
                })
                .catch((error: AxiosError) => {
                    reject(error);
                });
        });
    }

    public getCancelSource() {
        return axios.CancelToken.source();
    }

    public isCancel(err: AxiosError) {
        return axios.isCancel(err);
    }
}

export default ApiCore;
export {
    RawResponse,
    RawError
};
