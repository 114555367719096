
import { Component, Vue, Watch } from 'vue-property-decorator';
import Vendeur from '@/apiClient/resources/vendeur/vendeur';
import ListQuery from '@/apiClient/queryHelpers/listQuery';
import CreateUpdateVendeurForm from '@/components/CreateUpdateVendeurForm.vue';
import VendeurStatusLabel from '@/components/VendeurStatusLabel.vue';
import VendeurCategoryLabel from '@/components/VendeurCategoryLabel.vue';
import AddEditCallForm from '@/components/AddEditCallForm.vue';
import VendeurStatus from '@/modules/vendeurStatus';
import Navbar from '@/components/Navbar.vue';
import Pager from '@/apiClient/queryHelpers/pager';
import CallsDialog from '@/components/CallsDialog.vue';
import VendeurCategories from '@/modules/vendeurCategories';
import { ApiVendeurLineupCreate } from '@/apiClient/resources/vendeurLineup/apiVendeurLineup';
import SendToMailchimpBtn from "@/components/SendToMailchimpBtn.vue";
import VendeurTypes from "@/modules/vendeurTypes";
import TypeLabel from "@/components/TypeLabel.vue";
import StepLabel from "@/components/StepLabel.vue";
import Steps from "@/modules/steps";
import splitEmailString from "@/modules/splitEmailString";

@Component({
    components: {
        CreateUpdateVendeurForm,
        VendeurStatusLabel,
        VendeurCategoryLabel,
        TypeLabel,
        AddEditCallForm,
        CallsDialog,
        Navbar,
        SendToMailchimpBtn,
        StepLabel,
    }
})
export default class Vendeurs extends Vue {
    public $refs!: {
        callsDialog: any;
    };

    public splitEmailString = splitEmailString;
    public dialogCreateEditVisible = false;
    public loadingVendeurs = true;
    public selectedVendeur: Vendeur|null = null;
    public vendeurs: Vendeur[] = [];

    public statuses = VendeurStatus;
    public categories = VendeurCategories;
    public vendeurTypes = VendeurTypes;
    public steps = Steps;

    public showOnlyFieldsInForm: null|string[] = null;

    public nbPeopleToCall = 0;

    public filters: {[key: string]: any} = {
        status: '',
        category: '',
        type: '',
        closing_level: '',
        step: '',
        starred: '',
        min_price: 0,
        max_price: 0,
        next_call: '',
        hide_null_next_call: false,
        has_email: '',
        errors: '',
        next_action: '',
    };

    public searchFilter = '';

    public sortKey = 'next_call';
    public sortDirection = '';

    public vendeursCount = 0;
    public currentPage = 0;
    public pager: Pager = {
        limit: 12,
        offset: 0,
    }

    get showMailchimpBtn() {
        return this.$route.query.mailchimp === '1';
    }

    public mounted() {
        this.setFiltersFromQuery();
        this.loadVendeurs();
        this.loadVendeursToCall();
    }

    public rowClassName(data: {row: Vendeur; rowIndex: number}) {
        const vendeur = data.row;
        if (vendeur.todaysCall && vendeur.todaysCall.answered) {
            return 'called-today-with-answer';
        } else if (vendeur.todaysCall && !vendeur.todaysCall.answered) {
            return 'called-today-without-answer';
        }
        return '';
    }

    public onPageChange(index: number) {
        this.pager.offset = (index - 1) * this.pager.limit;
        this.loadVendeurs();
        this.refreshQuery();
    }

    public resetPagination() {
        this.pager.offset = 0;
        this.currentPage = 1;
    }

    public resetFilters(reload = true) {
        this.searchFilter = '';
        const filters = {
            ...this.filters,
        };

        filters.max_price = null;
        filters.min_price = null;
        filters.status = '';
        filters.category = '';
        filters.type = '';
        filters.closing_level = '';
        filters.step = '';
        filters.starred = '';
        filters.next_call = '';
        filters.hide_null_next_call = false;
        filters.has_email = '';
        filters.errors = '';
        filters.next_action = '';
        filters.id = '';

        this.filters = filters;
        this.onFiltersChange(reload);
    }

    public onFiltersChange(reload = true) {
        if (this.filters.errors === 'duplicates_telephone') {
            this.sortKey = 'telephone';
            this.sortDirection = '';
        } else if (this.filters.errors === 'duplicates_name') {
            this.sortKey = 'full_name';
            this.sortDirection = '';
        }
        this.resetPagination();
        this.refreshQuery();
        if (reload) {
            this.loadVendeurs();
        }
    }

    public resetSearch() {
        this.searchFilter = '';
        this.onSearchChange();
    }

    public onSearchChange() {
        this.resetPagination();
        this.refreshQuery();
        this.loadVendeurs();
    }

    public setFiltersFromQuery() {
        const query = this.$route.query;
        this.filters.min_price = query.min_price;
        this.filters.max_price = query.max_price;
        this.filters.has_email = query.has_email || '';
        this.filters.errors = query.errors || '';
        this.filters.status = query.status || '';
        this.filters.category = query.category || '';
        this.filters.type = query.type || '';
        this.filters.closing_level = query.closing_level || '';
        this.filters.step = query.step || '';
        this.filters.starred = query.starred || '';
        this.filters.next_call = query.next_call || '';
        this.filters.hide_null_next_call = query.hide_null_next_call === 'true';
        this.filters.next_action = query.next_action || '';
        this.filters.id = query.id;

        if (this.filters.id) {
            this.$notify.info({
                title: '',
                message: 'Vous avez lancé une recherche sur une personne particulière.' +
                    ' Pour retourner à la recherche normale, cliquez sur "Supprimer les filtres"',
                duration: 10000,
            });
        }

        this.searchFilter = query.search as string || '';
        if (query.page) {
            this.pager.offset = (Number(query.page) - 1) * this.pager.limit;
            this.currentPage = Number(query.page);
        }
        if (!this.currentPage) {
            this.currentPage = 1;
        }
        if (query.sort) {
            this.sortKey = (query.sort as string) || '';
        }
        if (query.sort_direction) {
            this.sortDirection = query.sort_direction as string || '';
        }
    }

    public refreshQuery() {
        this.$router.replace({
            name: this.$route.name as string,
            query: {
                ...this.filters,
                search: this.searchFilter,
                page: String(this.currentPage),
                sort: this.sortKey,
                sort_direction: this.sortDirection,
            },
        }).catch(() => {});
    }

    public loadCSV() {
        const listQ = this.getListQuery();
        return this.$api.vendeurs.loadCSV(listQ);
    }

    public getListQuery() {
        const filters = {
            ...this.filters,
        };
        if (filters.max_price === 0) {
            delete filters.max_price;
        }
        if (filters.min_price === 0) {
            delete filters.min_price;
        }
        for (const key in filters) {
            if (filters[key] === '') {
                delete filters[key];
            }
        }

        if (!filters.hide_null_next_call) {
            delete filters.hide_null_next_call;
        }

        if (filters.has_email === '') {
            delete filters.has_email;
        } else if (filters.has_email === '0') {
            filters.email_is_empty = 'true';
        } else if (filters.has_email === '1') {
            filters.email_is_empty = 'false';
        }

        if (filters.errors === '0') {
            filters.errors_is_empty = 'true';
        } else if (filters.errors === '1') {
            filters.errors_is_empty = 'false';
        } else if (filters.errors === 'duplicates_name') {
            filters.duplicates = 'full_name';
        } else if (filters.errors === 'duplicates_telephone') {
            filters.duplicates = 'telephone';
        } else {
            delete filters.errors;
        }

        delete filters.has_email;
        delete filters.errors;

        const isPhoneSearch = this.searchFilter.slice(0, 3).length === 3 && !isNaN(this.searchFilter.slice(0, 3) as any);
        if (isPhoneSearch) {
            filters.telephone = this.searchFilter;
        }

        return new ListQuery({
            filters,
            search: isPhoneSearch ? '' : this.searchFilter, // Special case, sent into filters
            pager: this.pager,
            sort: this.sortDirection + this.sortKey,
        });
    }

    public loadVendeurs() {
        this.loadingVendeurs = true;
        const listQ = this.getListQuery();

        this.$api.vendeurs.list(listQ)
            .then((res) => {
                this.vendeursCount = res.count || 0;
                this.vendeurs = res.results;
            })
            .catch((err) => {
                console.error(err);
            })
            .then(() => {
                this.loadingVendeurs = false;
            });
    }

    public loadVendeursToCall() {
        const listQ = new ListQuery({
            filters: {
                next_call: (new Date()).toISOString().slice(0, 10),
            },
            pager: {
                limit: 1,
                offset: 0,
            },
        });
        this.$api.vendeurs.list(listQ)
            .then((resp) => {
                this.nbPeopleToCall = resp.count || 0;
            })
            .catch((err) => {
            });
    }

    public showPeopleToCall() {
        this.resetFilters(false);
        this.filters.next_call = (new Date()).toISOString().slice(0, 10);
        this.onFiltersChange();
    }

    public showCreateForm() {
        this.showOnlyFieldsInForm = null;
        this.selectedVendeur = null;
        this.dialogCreateEditVisible = true;
    }

    public onSuccessCreateOrUpdate() {
        this.loadVendeurs();
        this.dialogCreateEditVisible = false;
        this.loadVendeursToCall();
    }

    public onSuccessCallCreate() {
        this.loadVendeurs(); // optimize TODO
    }

    public editVendeur(vendeur: Vendeur, fieldKey?: string) {
        this.showOnlyFieldsInForm = fieldKey ? [fieldKey] : null;
        this.selectedVendeur = vendeur;
        this.dialogCreateEditVisible = true;
    }

    public deleteVendeur(vendeur: Vendeur) {
        this.$confirm(`Confirmer la suppression de ${vendeur.contact.fullName}`,
            'Merci de confirmer', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Annuler',
                type: 'warning'
            })
            .then(() => {
                this.$api.vendeurs.delete(vendeur.id)
                    .then((res) => {
                        this.$notify.success('Vendeur supprimé avec succès');
                        this.loadVendeurs();
                    })
                    .catch((err) => {
                        this.$notify.error('Le vendeur n\'a pas pu être supprimé, merci de réessayer');
                    });

            }).catch(() => {
            // nothing
        });
    }

    public setVendeurStarred(vendeur: Vendeur, starred: boolean) {
        vendeur.starred = starred;
        this.$api.vendeurs.patch(vendeur.id, {
            starred,
        })
            .then(() => {
                if (starred) {
                    this.$notify.success("Vendeur marqué comme 'à suivre'");
                } else {
                    this.$notify.success("Vendeur enlevé des 'à suivre'");
                }
            })
            .catch((err) => {
                vendeur.starred = !starred;
                console.error(err);
                this.$notify.error('Une erreur est survenue, merci de réessayer');
            });
    }

    public addToLineup(vendeur: Vendeur) {
        this.$confirm(`Ajouter ${vendeur.contact.fullName} à la line up`)
            .then(() => {
                const payload: ApiVendeurLineupCreate = {
                    vendeur: vendeur.id,
                }
                this.$api.vendeurLineups.post([], payload)
                    .then(() => {
                        this.loadVendeurs();
                    })
                    .catch((err) => {
                        console.error(err);
                        this.$notify.error('Une erreur est survenue');
                    });
            })
            .catch(() => {
                // pass
            });
    }

    public viewInLineup(vendeur: Vendeur) {
        this.$router.push({
            name: 'lineup',
            query: {
                vendeurId: vendeur.id,
            },
        });
    }

    public addNewCall(vendeur: Vendeur) {
        this.$refs.callsDialog.addNewCall(vendeur);
    }

    public showCallsList(vendeur: Vendeur) {
        this.$refs.callsDialog.showCallsList(vendeur);
    }
}
