
import { Component, Vue, Prop } from 'vue-property-decorator';
import Vendeur from "@/apiClient/resources/vendeur/vendeur";

@Component({
    components: {
    }
})
export default class StepLabel extends Vue {

    @Prop({ default: null })
    public vendeur!: Vendeur;


}
