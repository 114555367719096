import VendeurAcheteurCommonTypes from "@/modules/vendeurAcheteurCommonTypes";

const AcheteurTypes: {[key: string]: {key: string; label: string; background?: string; color?: string}} = {
    ...VendeurAcheteurCommonTypes,
    PENSION: {
        key: 'caisse-pension',
        label: 'Caisse de pension',
    },
    BANK: {
        key: 'bank',
        label: 'Banque',
    },

    // TODO remove ?
    WITH_CONTRACT: {
        key: 'with_contract',
        label: 'Contrat signé'
    }
};

export default AcheteurTypes;
