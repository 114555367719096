
import { Component, Vue, Prop } from 'vue-property-decorator';
import ListQuery from "@/apiClient/queryHelpers/listQuery";

@Component({
    components: {
    }
})
export default class SendToMailchimpBtn extends Vue {

    @Prop({default: null})
    public listQuery!: ListQuery|null;

    @Prop({default: 'vendeurs'})
    public ficheType!: 'vendeurs'|'acheteurs';

    get listQueryWithEmails() {
        const listQ = new ListQuery(this.listQuery || {});
        listQ.filters.email_is_empty = 'false';
        return listQ;
    }

    public nbResultsWithEmail = 0;

    public showConfirm() {
        const query: any = this.ficheType === 'vendeurs' ? (a: any) => (this.$api.vendeurs.list(a)) : (a: any) => (this.$api.acheteurs.list(a));

        return query(this.listQueryWithEmails)
            .then((resp: {count: number}) => {
                this.nbResultsWithEmail = resp.count || 0;
                this.sendToMailchimp();
            })
            .catch((err: any) => {
                console.error(err);
                this.$notify.error('Une erreur est survenue');
            });
    }

    public sendToMailchimp() {
        this.$confirm('Confirmer', {
            message: `Les ${this.nbResultsWithEmail} contacts seront envoyé à Mailchimp si leur adresse email est valide`,
            dangerouslyUseHTMLString: true,
        })
            .then(() => {
                this.$api.mailchimp.addToAudience(this.listQueryWithEmails, this.ficheType)
                    .then(() => {
                        this.$notify.success("L'envoi à Mailchuimp est en cours. Il peut prendre quelques minutes.");
                    })
                    .catch((err) => {
                        this.$notify.error('Une erreur est survenue');
                    });
            })
            .catch(() => {
                //pass;
            })
    }
}
