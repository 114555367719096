import pick from '@/apiClient/queryHelpers/pick';
import ApiVendeurLineup from '@/apiClient/resources/vendeurLineup/apiVendeurLineup';
import ApiVendeur from '@/apiClient/resources/vendeur/apiVendeur';
import ApiAcheteur from '@/apiClient/resources/acheteur/apiAcheteur';
import Vendeur from '@/apiClient/resources/vendeur/vendeur';
import Acheteur from '@/apiClient/resources/acheteur/acheteur';

class VendeurLineup {
    public id!: number;
    public vendeur!: Vendeur;
    public acheteurs: Acheteur[] = [];
    public commission = 0;
    public cashed = 0;
    public timeCreated = '';
    public timeUpdated = '';
    public archived = false;
    public action = '';

    constructor(apiVendeurLU: ApiVendeurLineup) {
        this.id = apiVendeurLU.id;
        this.vendeur = new Vendeur(apiVendeurLU.vendeur);
        this.acheteurs = apiVendeurLU.acheteurs.map(Acheteur.deserialize);
        this.action = apiVendeurLU.action;
        this.commission = apiVendeurLU.commission || 0;
        this.cashed = apiVendeurLU.cashed || 0;
        this.timeCreated = apiVendeurLU.time_created;
        this.timeUpdated = apiVendeurLU.time_updated;
        this.archived = apiVendeurLU.archived;
    }

    public static deserialize(apiVendeurLU: ApiVendeurLineup) {
        return new VendeurLineup(apiVendeurLU);
    }

    get timeCreatedFormatted() {
        return new Date(this.timeCreated).toLocaleDateString();
    }

    get timeUdpatedFormatted() {
        return new Date(this.timeUpdated).toLocaleDateString();
    }

    public serialize(fields?: string[]): Partial<ApiVendeurLineup> {
        const apiVendeurLU: ApiVendeurLineup = {
            id: this.id,
            vendeur: this.vendeur.serialize() as ApiVendeur,
            acheteurs: this.acheteurs.map((ach) => (ach.serialize() as ApiAcheteur)),
            action: this.action,
            commission: this.commission,
            cashed: this.cashed,
            time_created: this.timeCreated,
            time_updated: this.timeUpdated,
            archived: this.archived,
        };

        if (fields) {
            return pick(apiVendeurLU, fields) as Partial<ApiVendeurLineup>;
        }

        return apiVendeurLU;
    }

}
export default VendeurLineup;
