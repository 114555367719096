
const Steps: {
    [key: string]: {
        key: string; label: string;
    };
} = {
    SIGN_MANDATE: {
        key: 'sign-mandate',
        label: 'Signer mandat',
    },
    CREATE_DOSSIER: {
        key: 'create-dossier',
        label: 'Faire le dossier',
    },
    FIND_BUYER: {
        key: 'find-buyer',
        label: 'Trouver un acheteur',
    },
    OBTAIN_OFFER: {
        key: 'obtain-offer',
        label: 'Obtenir une offre',
    },
    SIGN_OFFER: {
        key: 'sign-offer',
        label: "Signer l'offre",
    },
    NOTARIAL_DEED: {
        key: 'notarial-deed',
        label: 'Faire acte notarié',
    },
    NOTARY_SIGNED: {
        key: 'notary-signed',
        label: 'Signature chez notaire',
    },
    MONEY_IN: {
        key: 'money-in',
        label: 'Encaissement',
    },

};

export default Steps;
