import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import apiSingleton from '@/apiClient/apiSingleton';
import '@/globalComponents.ts';
import VueMq from 'vue-mq';
import VueClipboard from 'vue-clipboard2'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/fr';
import vueDebounce from 'vue-debounce';
import currencyFormatter from '@/modules/currencyFormatter';

import VueGoogleCharts from 'vue-google-charts';
Vue.use(VueGoogleCharts);
Vue.use(vueDebounce);
Vue.config.productionTip = false;

Vue.prototype.$api = apiSingleton;

Vue.use(VueClipboard);

Vue.use(ElementUI, { locale })

Vue.use(VueMq, {
    breakpoints: {
        mobile: 700,
        tablet: 1000,
        laptop: 1250,
        desktop: Infinity,
    },
});

const CurrencyFormatter = {
    install(Vue: any, options: any) {
        Vue.prototype.formatCurrency = (a?: number|null) => {
            return a ? currencyFormatter(a) : '';
        };
    },
}
Vue.use(CurrencyFormatter);

declare module 'vue/types/vue' {
    interface Vue {
        $api: typeof apiSingleton;
        $mq: typeof VueMq;
    }
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
