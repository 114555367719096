import ApiVendeurLineup, { ApiVendeurLineupCreate, ApiVendeurLineupUpdate } from '@/apiClient/resources/vendeurLineup/apiVendeurLineup';
import ApiClient from '@/apiClient/core/apiClient';
import ApiServiceDeserialized from '@/apiClient/resources/apiServiceDeserialized';
import VendeurLineup from '@/apiClient/resources/vendeurLineup/vendeurLineup';

class VendeurLineupService
    extends ApiServiceDeserialized<
        VendeurLineup,
        ApiVendeurLineup,
        ApiVendeurLineupUpdate,
        ApiVendeurLineupCreate> {

    public static readonly ENDPOINTS = ['vendeur-lineups'];

    constructor(client: ApiClient) {
        super(client, VendeurLineupService.ENDPOINTS, VendeurLineup.deserialize);
    }

}

export default VendeurLineupService;
