import ApiListResult from './apiListResult';

class ListResult<T> {
    public results: T[];
    public count?: number;
    public previous: string|null = null;
    public next: string|null = null;

    /**
     *
     * @param {Array<Object>} objects
     * @param {number=} count
     * @param {string=} previous
     * @param {string=} next
     */
    constructor(objects: T[], count?: number, previous?: string|null, next?: string|null) {
        this.results = objects;
        this.count = count;
        this.previous = previous || null;
        this.next = next || null;
    }

    get hasNext() {
        return !!this.next;
    }

    public static deserialize<ApiT>(apiList: ApiListResult<ApiT>, deserializeObj: any) {
        return new ListResult(
            apiList.results.map(deserializeObj),
            apiList.count,
            apiList.previous,
            apiList.next);
    }

}

export default ListResult;
