import ApiClient, { CoreQueryConfig } from './apiClient';
import { Method } from 'axios';

class ApiService<T = any, ListT = any, UpdateT = any, CreateT = any> {

    public client: ApiClient;

    /**
     * @param {ApiClient} client
     */
    constructor(client: ApiClient) {
        this.client = client;
    }

    public _get(queryConfig: CoreQueryConfig): Promise<T> {
        return this.client.get<T>(queryConfig);
    }

    public _list(queryConfig: CoreQueryConfig): Promise<ListT> {
        return this.client.get<ListT>(queryConfig);
    }

    public _patch(queryConfig: CoreQueryConfig, data: UpdateT) {
        return this.client.patch<UpdateT, T>(queryConfig, data);
    }

    public _post(queryConfig: CoreQueryConfig, data: CreateT) {
        return this.client.post<CreateT, T>(queryConfig, data);
    }

    public _delete(queryConfig: CoreQueryConfig) {
        return this.client.delete(queryConfig);
    }

    public _request<D = any, R = any>(method: Method, queryConfig: CoreQueryConfig, data?: D) {
        return this.client.request<D, R>(method, queryConfig, data);
    }

}

export default ApiService;
