
import { Component, Vue, Prop } from 'vue-property-decorator';
import currencyFormatter from '@/modules/currencyFormatter';


@Component({
    components: {
    }
})
export default class EtatLocatifFormItem extends Vue {
    @Prop()
    public value!: number;

    public currencyFormatter = currencyFormatter;

    public onValueChange(newVal: number|null) {
        newVal = newVal || 0;
        this.$emit('input', newVal);
    }
}

