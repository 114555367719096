import Pager from './pager';
import { KeyValue } from './keyValue';
import ListFilters from './listFilters';

class ListQuery {

    public pager?: Pager;
    public search?: string;
    public sort?: string;
    public filters: ListFilters;

    constructor(listQuery?: Partial<ListQuery>) {
        const query = listQuery || {} as Partial<ListQuery>;
        this.search = query.search;
        this.sort = query.sort;
        this.filters = query.filters || {};
        this.pager = query.pager || { offset: 0, limit: 10 };
    }

    public getQueryString() {
        const urlParamsArray: string[] = [];
        if (this.search) {
            urlParamsArray.push('search=' + encodeURIComponent(this.search));
        }
        if (this.sort) {
            urlParamsArray.push('ordering=' + encodeURIComponent(this.sort));
        }

        if (this.filters) {
            const filters: ListFilters = this.filters;
            Object.keys(filters).forEach((key: string) => {
                const val = filters[key];
                if (val !== null && typeof val !== 'undefined') {
                    const encodedVal = encodeURIComponent(val);
                    urlParamsArray.push(`${key}=${encodedVal}`);
                }
            });
        }

        if (this.pager) {
            urlParamsArray.push('offset=' + (this.pager.offset * this.pager.limit));
            urlParamsArray.push('limit=' + this.pager.limit);
        }

        return '?' + urlParamsArray.join('&');
    }

    public getQueryParams(): Array<KeyValue<string, string>> {
        const urlParamsArray: Array<KeyValue<string, string>> = [];
        if (this.search) {
            urlParamsArray.push({
                key: 'search',
                value: encodeURIComponent(this.search)
            });
        }
        if (this.sort) {
            urlParamsArray.push({
                key: 'ordering',
                value: encodeURIComponent(this.sort)
            });
        }

        if (this.filters) {
            const filters: ListFilters = this.filters;
            Object.keys(filters).forEach((key: string) => {
                const val = filters[key];
                if (val !== null && typeof val !== 'undefined') {
                    const encodedVal = encodeURIComponent(val);
                    urlParamsArray.push({
                        key,
                        value: encodedVal
                    });
                }
            });
        }

        if (this.pager) {
            urlParamsArray.push({
                key: 'offset',
                value: (this.pager.offset).toString()
            });
            urlParamsArray.push({
                key: 'limit',
                value: this.pager.limit.toString()
            });
        }

        return urlParamsArray;
    }
}

export default ListQuery;
