
import { Component, Vue, Watch } from 'vue-property-decorator';
import XLSX from 'xlsx';
import { ApiCallNoteCreate, ApiCallNoteUpdate } from '@/apiClient/resources/callNote/apiCallNote';
import { ApiAcheteurCreate } from '@/apiClient/resources/acheteur/apiAcheteur';
import AcheteurTypes from '@/modules/acheteurTypes';
import callsSplitter from '@/modules/callsSplitter';

interface AcheteurToCreate extends ApiAcheteurCreate {
    calls: (ApiCallNoteUpdate|ApiCallNoteCreate)[];
}

const make_cols = (refstr: any) => Array(XLSX.utils.decode_range(refstr).e.c + 1).fill(0)
    .map((x, i) => ({ name: XLSX.utils.encode_col(i), key: i }));

@Component({
    components: {
    }
})
export default class ImportCsv extends Vue {

    public loading = false;
    public acheteursToCreate: AcheteurToCreate[] = [];
    public createdIndexes: number[] = []; // already created

    public acheteurTypes = AcheteurTypes;
    public globalType = '';
    public globalNextCall = '';
    public globalAction = '';

    @Watch('globalType')
    public onGlobalTypeChange() {
        if (this.globalType) {
            this.acheteursToCreate.forEach((v: AcheteurToCreate) => {
                v.type = this.globalType;
            });
        }
    }

    public validateGlobalAction() {
        this.acheteursToCreate.forEach((v) => {
            v.next_action = this.globalAction;
        });
    }

    @Watch('globalNextCall')
    public onGlobalNextCallChange() {
        const nextCall = this.globalNextCall ? this.globalNextCall : '';
        this.acheteursToCreate.forEach((v) => {
            v.next_call = nextCall;
        });
    }

    public createAcheteurs() {
        this.loading = true;
        const allPromises: any[] = this.acheteursToCreate.map((acheteurData, i) => {
            const calls = acheteurData.calls;
            delete acheteurData.calls;

            if (acheteurData.next_call) {
                const nC = new Date(acheteurData.next_call);
                nC.setHours(14);
                acheteurData.next_call = nC.toISOString();
            } else if (acheteurData.next_call === '') {
                delete acheteurData.next_call;
            }

            if (acheteurData.estate_yield && typeof acheteurData.estate_yield === 'string') {
                acheteurData.estate_yield = (acheteurData.estate_yield as any).toString().replace(',', '.');
                acheteurData.estate_yield = parseFloat(acheteurData.estate_yield as any) || 0;
            } else if (acheteurData.estate_yield) {
                // pass
            } else {
                acheteurData.estate_yield = 0;
            }

            return this.$api.acheteurs.post([], acheteurData)
                .then((newAcheteur) => {
                    this.createdIndexes.push(i);

                    // Create "calls"
                    if (calls && calls.length > 0) {
                        const callsToCreate: ApiCallNoteCreate[] = calls.map((newCall) => {
                            try {
                                newCall.date = new Date(newCall.date).toISOString();
                            } catch (err) {
                                console.log('Bad date', newCall.date, newCall.notes);
                                newCall.date = new Date('2018-01-01').toISOString();
                            }

                            return {
                                ...newCall,
                                object_id: newAcheteur.id,
                                content_type: 9, // acheteur
                            } as any;
                        });

                        return this.$api.callNotes.post([], callsToCreate).then(() => {
                            return null;
                        })
                    } else {
                        return Promise.resolve();
                    }

                });
        });

        Promise.all(allPromises).then((res) => {
            this.loading = false;
            this.$notify.success('Les éléments ont été ajoutés avec succès');
            this.acheteursToCreate = [];
        });
    }

    public deleteRow(rowI: number) {
        this.acheteursToCreate.splice(rowI, 1);
    }

    public onFileChange(e: any) {
        /* Boilerplate to set up FileReader */
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target?.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            let data = XLSX.utils.sheet_to_json(ws, { header: 1 });

            data = data.filter((row: any) => {
                return !!row && row.length > 0;
            })

            console.log('Data', data);
            this.acheteursToCreate = data.map((row: any) => {
                const callsArray: ApiCallNoteCreate[] = callsSplitter(row[3]);

                return {
                    company_name: row[0],
                    full_name: row[1],
                    telephone: row[2],
                    email: row[7],
                    address: `${row[4] || ''} ${row[5] || ''} ${row[6] || ''}`,

                    type: '',

                    estate_region: '',
                    estate_nb_flats: null,
                    estate_yield: null,
                    estate_price_min: null,
                    estate_price_max: null,

                    // Other
                    notes: '',
                    next_call: '',
                    next_action: '',

                    calls: callsArray,
                }
            });

            console.log(data, make_cols(ws['!ref']))
        };
        reader.readAsBinaryString(file);
    }

    public removeNote(acheteur: AcheteurToCreate, noteIndex: number) {
        acheteur.calls.splice(noteIndex, 1);
    }

}
