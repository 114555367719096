import UserService from '@/apiClient/resources/user/userService';
import ApiClient from '@/apiClient/core/apiClient';
import ApiConfiguration from '@/apiClient/core/apiConfiguration';
import VendeurService from '@/apiClient/resources/vendeur/vendeurService';
import AcheteurService from '@/apiClient/resources/acheteur/acheteurService';
import CallNoteService from '@/apiClient/resources/callNote/callNoteService';
import VendeurLineupService from "@/apiClient/resources/vendeurLineup/vendeurLineupService";
import MailchimpService from "@/apiClient/resources/mailchimp/mailchimpService";

class AZImmoClient extends ApiClient {

    public defaultHeaders = [
        {
            key: 'Accept',
            value: 'application/json'
        },
        {
            key: 'Content-Type',
            value: 'application/json'
        }
    ];

    /**
     *
     * @param {ApiConfiguration} apiConfig
     */
    constructor(apiConfig: ApiConfiguration) {
        super(apiConfig);
        this.setHeaders(this.defaultHeaders);
    }

    public setToken(token: string) {
        this.setHeader({
            key: 'Authorization',
            value: token ? 'Bearer ' + token : '',
        });
    }

    public users: UserService = new UserService(this);
    public vendeurs: VendeurService = new VendeurService(this);
    public callNotes: CallNoteService = new CallNoteService(this);
    public acheteurs: AcheteurService = new AcheteurService(this);
    public vendeurLineups: VendeurLineupService = new VendeurLineupService(this);
    public mailchimp: MailchimpService = new MailchimpService(this);

}
export default AZImmoClient;
