
const VendeurAcheteurCommonTypes: {[key: string]: {key: string; label: string; background?: string; color?: string}} = {
    PRIVATE: {
        key: 'particulier',
        label: 'Particulier',
    },
    COMPANY: {
        key: 'company',
        label: 'Entreprise',
    },
    APPORTEUR: {
        key: 'apporteur',
        label: "Apporteur d'affaire",
    },
    AGENCE_IMMO: {
        key: 'agence-immobiliere',
        label: 'Agence immobilière'
    },
    COURTIER: {
        key: 'courtier',
        label: "Courtier",
    },
    GERANCE: {
        key: 'gerance',
        label: "Gérance",
    },
};

export default VendeurAcheteurCommonTypes;
