
import { Component, Vue, Watch } from 'vue-property-decorator';
import Navbar from '@/components/Navbar.vue';
import { ApiCallNoteCount, ApiCallNoteCountResp } from '@/apiClient/resources/callNote/apiCallNote';

@Component({
    components: {
        Navbar,
    }
})
export default class Statistics extends Vue {

    public loading = true;
    public blockReload = false;

    public dateStart = new Date(new Date().getTime() - 1000 * 3600 * 24 * 30);
    public dateEnd = this.getTodayLate();

    public allCalls: any = [];
    public allCallsVendeurs: any = [];
    public allCallsAcheteurs: any = [];
    public callsAnswered: any = [];
    public callsAnsweredVendeurs: any = [];
    public callsAnsweredAcheteurs: any = [];

    public aggregation = 'week';

    public created() {
        this.setTo4Weeks();
    }

    public onDateStartChange() {
        this.loadCallsSummary();
    }

    public onDateEndChange() {
        this.loadCallsSummary();
    }

    @Watch('aggregation')
    public onAggregationChange() {
        this.loadCallsSummary();
    }

    public loadCallsSummary() {
        if (this.blockReload) {
            return;
        }
        this.loading = true;
        const vendeurCType = 6;
        const acheteurCType = 9;

        this.setDateStartHours();
        this.setDateEndHours();

        const allPromises = [];
        // all calls
        allPromises.push(this.$api.callNotes.count(this.dateStart, this.dateEnd, this.aggregation)
            .then((results: ApiCallNoteCountResp) => {
                this.setChartData(results, this.allCalls);
            }));

        // all answered calls
        allPromises.push(this.$api.callNotes.count(this.dateStart, this.dateEnd, this.aggregation, undefined, true)
            .then((results: ApiCallNoteCountResp) => {
                this.setChartData(results, this.callsAnswered);
            }));

        // Vendeurs: all calls
        allPromises.push(this.$api.callNotes.count(this.dateStart, this.dateEnd, this.aggregation, vendeurCType)
            .then((results: ApiCallNoteCountResp) => {
                this.setChartData(results, this.allCallsVendeurs);
            }));

        // Acheteurs: all calls
        allPromises.push(this.$api.callNotes.count(this.dateStart, this.dateEnd, this.aggregation, acheteurCType)
            .then((results: ApiCallNoteCountResp) => {
                this.setChartData(results, this.allCallsAcheteurs);
            }));

        // Vendeurs: answered
        allPromises.push(this.$api.callNotes.count(this.dateStart, this.dateEnd, this.aggregation, vendeurCType, true)
            .then((results: ApiCallNoteCountResp) => {
                this.setChartData(results, this.callsAnsweredVendeurs);
            }));

        // Acheteurs: answered
        allPromises.push(this.$api.callNotes.count(this.dateStart, this.dateEnd, this.aggregation, acheteurCType, true)
            .then((results: ApiCallNoteCountResp) => {
                this.setChartData(results, this.callsAnsweredAcheteurs);
            }));

        Promise.all(allPromises)
            .then(() => {
                this.loading = false;
            })
    }

    public setDateStartHours() {
        const offsetHours = this.dateStart.getTimezoneOffset() / 60; // in minutes
        this.dateStart.setHours(5 - offsetHours, 0, 0);
    }

    public setDateEndHours() {
        const offsetHours = this.dateEnd.getTimezoneOffset() / 60; // in minutes
        this.dateEnd.setHours(20 - offsetHours, 59, 0);
    }

    public setChartData(resp: ApiCallNoteCountResp, callsArray: any[]) {
        callsArray.length = 0;
        callsArray.push(...resp.call_counts.map((el: any, i) => {
            if (i === 0) { // header
                return el;
            }
            return [new Date(el[0]), el[1]];
        }));
    }

    public getChartOptions(callCounts: ApiCallNoteCount[]) {
        const ticks = callCounts.slice(1).map((c: any) => c[0]);
        return {
            chart: {
                title: 'Appels',
                subtitle: 'Appels passés',
                legend: 'none'
            },
            legend: 'none',
            vAxis: {
                format: '0',
                viewWindow: {
                    min: 0,
                }
            },
            hAxis: {
                format: this.aggregation === 'month' ? 'MMM' : 'd.MM',
                ticks: ticks,
            },
            pointSize: 5
        };
    }

    public getTodayLate() {
        const d = new Date();
        d.setHours(23, 55);
        return d;
    }

    public setToMonday(d: Date) {
        const weekDayStart = d.getDay();
        d.setDate(d.getDate() - weekDayStart + 1);
    }

    public setToSunday(d: Date) {
        const weekDayStart = d.getDay();
        d.setDate(d.getDate() - weekDayStart + 7);
    }

    public setToRange(duration = 'month', amount = 1) {
        this.blockReload = true;
        this.aggregation = duration;

        const dStart = new Date();
        const dateEnd = this.getTodayLate();

        if (duration === 'month') {
            dStart.setMonth(dStart.getMonth() - amount);
            dStart.setDate(1);
        } else if (duration === 'week') {
            dStart.setDate(dStart.getDate() - amount * 7);
            this.setToMonday(dStart);
            this.setToSunday(dateEnd);
        } else if (duration === 'day') {
            dStart.setDate(dStart.getDate() - amount);
        }

        this.dateStart = dStart;
        this.dateEnd = dateEnd;

        this.blockReload = false;
        this.loadCallsSummary();
    }

    public setTo3Months() {
        this.setToRange('month', 3);
    }

    public setTo4Weeks() {
        this.setToRange('week', 4);
    }

    public setTo7Days() {
        this.setToRange('day', 7);
    }

}
