
import { Component, Vue, Prop } from 'vue-property-decorator';
import currencyFormatter from '@/modules/currencyFormatter';
import VendeurLineup from '@/apiClient/resources/vendeurLineup/vendeurLineup';
import Acheteur from "@/apiClient/resources/acheteur/acheteur";
import ListQuery from "@/apiClient/queryHelpers/listQuery";

@Component({
    components: {

    },
})
export default class FindAcheteurToAddToLineup extends Vue {

    @Prop({default: () => ([])})
    public acheteursAlreadyThere!: string[];

    public acheteurs: Acheteur[] = [];
    public searchFilter = '';
    public loading = false;

    public created() {
        this.loadAcheteurs();
    }

    public addAcheteur(acheteur: Acheteur) {
        this.$emit('addAcheteur', acheteur);
    }

    public loadAcheteurs() {
        const listQ = new ListQuery({
            search: this.searchFilter,
            pager: {
                limit: 15,
                offset: 0,
            },
        });
        this.loading = true;
        this.$api.acheteurs.list(listQ)
            .then((resp) => {
                this.acheteurs = resp.results;
            })
            .catch((err) => {
                console.error(err);
                this.$notify.error("Une erreur est survenue");
            })
            .finally(() => {
                this.loading = false;
            });
    }


}
