
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ApiAcheteurCreate } from '@/apiClient/resources/acheteur/apiAcheteur';
import Acheteur from '@/apiClient/resources/acheteur/acheteur';
import AcheteurTypes from '@/modules/acheteurTypes';
import currencyFormatter from '@/modules/currencyFormatter';
import nextCallPickerOptions from '@/modules/nextCallPickerOptions';
import Vendeur from '@/apiClient/resources/vendeur/vendeur';
import ApiVendeur from '@/apiClient/resources/vendeur/apiVendeur';
import CreateUpdateVendeurForm from '@/components/CreateUpdateVendeurForm.vue';
import EmailsListEdit from "@/components/sharedFormItems/EmailsListEdit.vue";
import splitEmailString from "@/modules/splitEmailString";

@Component({
    components: {
        CreateUpdateVendeurForm,
        EmailsListEdit,
    }
})
export default class CreateUpdateAcheteurForm extends Vue {

    @Prop({ default: null })
    public showOnlyFields!: null|string[];

    public currencyFormatter = currencyFormatter;

    @Prop({ default: null })
    public acheteur!: Acheteur; // acheteur to edit, if any

    // create and update are the same
    public originalFormData: ApiAcheteurCreate = {
        company_name: '',
        full_name: '',
        telephone: '',
        email: '',
        address: '',
        address2: '',

        type: '',

        // estate
        estate_region: '',
        estate_nb_flats: null,
        estate_yield: null,
        estate_price_min: null,
        estate_price_max: null,

        // other
        next_call: '',
        next_action: '',
    }

    public errorsMap: any = null;
    public getError(errorKey: string) {
        if (this.errorsMap && this.errorsMap[errorKey] && Array.isArray(this.errorsMap[errorKey])) {
            return this.errorsMap[errorKey][0] || '';
        }
    }

    public form: ApiAcheteurCreate = {
        ...this.originalFormData,
    }

    public linkedVendeur: Vendeur|null = null;
    public addLinkedFormVisible = false;

    public acheteurTypes = AcheteurTypes;

    public pickerOptions = nextCallPickerOptions;

    public created() {
        this.resetForm();
    }

    public resetForm() {
        this.form = {
            ...this.originalFormData,
        };
        if (this.acheteur) {
            const seriAcheteur = this.acheteur.serialize(Object.keys(this.form)) as ApiAcheteurCreate;
            this.form = {
                ...seriAcheteur,
            };
            this.linkedVendeur = this.acheteur.linked ? Vendeur.deserialize(this.acheteur.linked.serialize() as ApiVendeur) : null;
        }
    }

    public isFieldShown(fieldKey: string) {
        return !this.showOnlyFields || this.showOnlyFields.includes(fieldKey);
    }

    public onEmailChange(emails: string[]) {
        this.form.email = splitEmailString((emails || []).join(', ')).join(', ');
    }

    public createOrUpdateAcheteur() {
        const payload: {[key: string]: any} = {
            ...this.form,
            linked_vendeur: this.linkedVendeur?.id,
        };

        const isNullable = (key: string) => {
            return ['next_call', 'estate_nb_flats', 'estate_price_min', 'estate_price_max', 'estate_yield'].includes(key);
        }

        delete payload.notes;

        for (const key in payload) {
            if (payload[key] === '' && isNullable(key)) {
                payload[key] = null;
            }
        }

        if (!payload.estate_yield) {
            payload.estate_yield = 0;
        } else if (payload.estate_yield) {
            payload.estate_yield = payload.estate_yield.toString().replace(',', '.');
            payload.estate_yield = parseFloat(payload.estate_yield);
        }

        this.errorsMap = null;
        const isEdit = !!this.acheteur;

        const request = isEdit
            ? this.$api.acheteurs.patch.bind(this.$api.acheteurs) : this.$api.acheteurs.post.bind(this.$api.acheteurs);
        const uuid = isEdit ? this.acheteur.id : [];

        request(uuid as any, payload as ApiAcheteurCreate)
            .then((res) => {
                const word = isEdit ? 'modifié' : 'créé';
                this.$notify.success('L\'acheteur a été ' + word + ' avec succès');
                this.$emit('success', res);
            })
            .catch((err) => {
                console.error(err);
                this.errorsMap = err.response?.data;
                this.$notify.error('Une erreur est survenue, merci de réessayer'); // TODO show errs.
            });
    }

    public onSuccessCreateVendeur(data: Vendeur) {
        this.linkedVendeur = data || null;
    }

    public showLinkedForm() {
        this.addLinkedFormVisible = true;
    }

}
