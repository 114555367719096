
import { Component, Vue, Prop } from 'vue-property-decorator';
import currencyFormatter from '@/modules/currencyFormatter';
import VendeurLineup from '@/apiClient/resources/vendeurLineup/vendeurLineup';
import FindAcheteurToAddToLineup from "@/components/FindAcheteurToAddToLineup.vue";
import Acheteur from "@/apiClient/resources/acheteur/acheteur";
import {ApiVendeurUpdate} from "@/apiClient/resources/vendeur/apiVendeur";
import ClosingLevelFormItem from "@/components/sharedFormItems/ClosingLevelFormItem.vue";
import StepFormItem from "@/components/sharedFormItems/StepFormItem.vue";
import EstateCategoryFormItem from "@/components/sharedFormItems/EstateCategoryFormItem.vue";
import SalePriceFormItem from "@/components/sharedFormItems/SalePriceFormItem.vue";
import EtatLocatifFormItem from "@/components/sharedFormItems/EtatLocatifFormItem.vue";

@Component({
    components: {
        EtatLocatifFormItem,
        FindAcheteurToAddToLineup,
        ClosingLevelFormItem,
        StepFormItem,
        EstateCategoryFormItem,
        SalePriceFormItem,
    },
})
export default class UpdateVendeurLineupForm extends Vue {

    public currencyFormatter = currencyFormatter;

    @Prop()
    public vendeurLineup!: VendeurLineup; // lineup object to edit

    public lineupFormData = {
        cashed: this.vendeurLineup.cashed,
        commission: this.vendeurLineup.commission,
        action: this.vendeurLineup.action,
        archived: this.vendeurLineup.archived,
        acheteurs: [...this.vendeurLineup.acheteurs],
    }

    public vendeurFormData: ApiVendeurUpdate = {
        closing_level: this.vendeurLineup.vendeur.closingLevel || null,
        step: this.vendeurLineup.vendeur.step || '',
        estate_category: this.vendeurLineup.vendeur.estate.category || '',
        estate_price: this.vendeurLineup.vendeur.estate.price,
        estate_etat_locatif: this.vendeurLineup.vendeur.estate.etatLocatif,
    }

    public dialogShowFindAcheteurVisible = false;

    public created() {

    }

    public deleteAcheteur(acheteurId: string) {
        this.lineupFormData.acheteurs = this.lineupFormData.acheteurs.filter((ach) => (ach.id !== acheteurId));
    }

    public addAcheteur(acheteur: Acheteur) {
        if (!this.lineupFormData.acheteurs.find((ach) => (ach.id === acheteur.id))) {
            this.lineupFormData.acheteurs.push(acheteur);
        }
    }

    public save() {
        const payloadLineup = {
            ...this.lineupFormData,
            acheteurs: this.lineupFormData.acheteurs.map((ach) => (ach.id)),
        };
        Object.keys(payloadLineup).forEach((key) => {
            if (["commission", "cashed"].includes(key) && !(payloadLineup as any)[key]) {
                (payloadLineup as any)[key] = 0;
            }
        });

        const onSuccess = () => {
            this.$notify.success("Sauvegardé avec succès");
            this.$emit('success');
        };

        this.$api.vendeurLineups.patch(String(this.vendeurLineup.id), payloadLineup)
            .then(() => {
                const payloadVendeur = {
                    ...this.vendeurFormData,
                };
                const vendeurHasChanged = true;
                if (vendeurHasChanged) {
                    return this.$api.vendeurs.patch(this.vendeurLineup.vendeur.id, payloadVendeur)
                        .then(() => {
                            onSuccess();
                        });
                } else {
                    onSuccess();
                }
            })
            .catch((err) => {
                console.error(err);
                this.$notify.error("Une erreur est survenue");
            });


    }

    public cancel() {
        this.$emit('cancel');
    }

}
