
const VendeurStatus: {[key: string]: {key: string; label: string; background?: string; color?: string}} = {
    COORDONNEES: {
        key: 'coordonnees',
        label: 'Coordonnées',
        background: '#f0f0f0',
        color: '#777',
    },
    IDENTIFIED: {
        key: 'identified',
        label: 'Identifié',
        background: 'lightblue',
        color: 'blue',
    },
    WAS_SELLER: {
        key: 'was_seller',
        label: 'Était vendeur',
    },
    NOT_READY: {
        key: 'not_ready',
        label: 'Pas mûr',
        background: 'lightblue',
        color: '#009ee5',
    },
    NOT_IN_NEED: {
        key: 'not_in_need',
        label: 'Pas de nécessité',
        background: '#e6dff9',
        color: '#8546ce',
    },
    NOT_SELLER: {
        key: 'not_seller',
        label: 'Pas vendeur',
        background: '#ededed',
        color: '#333',
    },
    HERITAGE: {
        key: 'heritage',
        label: 'Héritage',
        background: '#f0f0f0',
        color: '#777',
    },
    WANTS_TO_SELL: {
        key: 'want_to_sell',
        label: 'Veut vendre',
        background: '#cffbf2',
        color: '#039d7e',
    },
    SALE_IN_PROCESS: {
        key: 'sale_in_process',
        label: 'Vente en cours',
        background: '#d7f3d6',
        color: '#1b7618',
    },
    SALE_DONE: {
        key: 'sale_done',
        label: 'Vente finalisée',
        background: '#baf5b9',
        color: '#1b7618',
    }
};

export default VendeurStatus;
