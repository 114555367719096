
import { Component, Vue, Watch } from 'vue-property-decorator';
import auth from '@/apiClient/auth';

@Component({
    components: {

    }
})
export default class NotFound extends Vue {

}
