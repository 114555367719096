
import {Component, Vue, Watch} from 'vue-property-decorator';
import Navbar from '@/components/Navbar.vue';
import VendeurLineup from '@/apiClient/resources/vendeurLineup/vendeurLineup';
import VendeurCategoryLabel from '@/components/VendeurCategoryLabel.vue';
import UpdateVendeurLineupForm from "@/components/UpdateVendeurLineupForm.vue";
import ListQuery from "@/apiClient/queryHelpers/listQuery";
import VendeurStatusLabel from "@/components/VendeurStatusLabel.vue";
import LineupAcheteursList from "@/components/LineupAcheteursList.vue";
import LineUpAcheteursMatching from "@/components/LineUpAcheteursMatching.vue";
import TypeLabel from "@/components/TypeLabel.vue";
import StepLabel from "@/components/StepLabel.vue";
import Vendeur from "@/apiClient/resources/vendeur/vendeur";
import CallsDialog from "@/components/CallsDialog.vue";
import splitEmailString from '@/modules/splitEmailString';

@Component({
    components: {
        Navbar,
        VendeurCategoryLabel,
        TypeLabel,
        StepLabel,
        UpdateVendeurLineupForm,
        VendeurStatusLabel,
        LineupAcheteursList,
        LineUpAcheteursMatching,
        CallsDialog,
    }
})
export default class Lineup extends Vue {
    public $refs!: {
        callsDialog: any;
    };

    public splitEmailString = splitEmailString;
    public lineups: VendeurLineup[] = [];
    public loading = false;
    public dialogEditVisible = false;
    public selectedVendeurLineup: VendeurLineup|null = null;
    public showArchived = false;
    public sortKey = 'vendeur__full_name';
    public sortDirection = '';

    public created() {
        this.setFiltersFromQuery();
        this.loadVendeurLineups();
    }

    @Watch('showArchived')
    public onShowArchivedChanged() {
        this.loadVendeurLineups();
    }

    public loadVendeurLineups() {
        this.refreshQuery();
        this.loading = true;
        const listQuery = new ListQuery({
            filters: {
                archived: this.showArchived ? '' : 'False',
            },
            sort: this.sortDirection + this.sortKey,
        });
        this.$api.vendeurLineups.listAll(listQuery)
            .then((resp) => {
                this.lineups = resp.results;
                if (this.$route.query.vendeurId) {

                }
            })
            .catch((err) => {
                console.error(err);
                this.$notify.error("La line up n'a pas pu être chargée");
            })
            .finally(() => {
                this.loading = false;
            });
    }

    public editVendeurLineup(vendeurLU: VendeurLineup) {
        this.selectedVendeurLineup = vendeurLU;
        this.dialogEditVisible = true;
    }

    public deleteVendeurLineup(vendeurLU: VendeurLineup) {
        this.$confirm("Êtes-vous sûr de vouloir l'enlever de la line up ?", {
            type: 'warning',
        })
            .then(() => {
                this.$api.vendeurLineups.delete(String(vendeurLU.id))
                    .then(() => {
                        this.loadVendeurLineups();
                        this.$notify.success("Élément supprimé");
                    })
                    .catch((err) => {
                        console.error(err);
                        this.$notify.error("Une erreur est survenue");
                    });
            })
            .catch(() => {
                // pass
            });
    }

    public onSuccessUpdate() {
        this.loadVendeurLineups();
        this.dialogEditVisible = false;
        this.selectedVendeurLineup = null;
    }

    public onCancelUpdate() {
        this.dialogEditVisible = false;
        this.selectedVendeurLineup = null;
    }

    public rowClassName(data: {row: VendeurLineup; rowIndex: number}) {
        const vLU = data.row;
        if (vLU.archived) {
            return 'lineup-archived';
        }
    }

    public setFiltersFromQuery() {
        const query = this.$route.query;
        if (query.sort) {
            this.sortKey = (query.sort as string) || '';
        }
        if (query.sort_direction) {
            this.sortDirection = query.sort_direction as string || '';
        }
    }

    public refreshQuery() {
        this.$router.replace({
            name: this.$route.name as string,
            query: {
                sort: this.sortKey,
                sort_direction: this.sortDirection,
            },
        }).catch(() => {});
    }

    public addNewCall(vendeur: Vendeur) {
        this.$refs.callsDialog.addNewCall(vendeur);
    }

    public showCallsList(vendeur: Vendeur) {
        this.$refs.callsDialog.showCallsList(vendeur);
    }

    public onSuccessCallCreate() {
        this.loadVendeurLineups(); // optimize TODO
    }

}
