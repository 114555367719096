import ApiUser from '@/apiClient/resources/user/apiUser';
import pick from '@/apiClient/queryHelpers/pick';

class User {
    public id: number;
    public username: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public isActive: boolean;
    public isStaff: boolean;
    public isSuperuser: boolean;
    public setPasswordToken?: string;

    constructor(apiUser: ApiUser) {
        this.id = apiUser.id;
        this.username = apiUser.username;
        this.firstName = apiUser.first_name;
        this.lastName = apiUser.last_name;
        this.email = apiUser.email;
        this.isActive = apiUser.is_active;
        this.isStaff = apiUser.is_staff;
        this.isSuperuser = apiUser.is_superuser;
        this.setPasswordToken = apiUser.set_password_token;
    }

    get fullName() {
        return `${this.firstName || ''} ${this.lastName || ''}`;
    }

    public static deserialize(apiUser: ApiUser) {
        return new User(apiUser);
    }

    public serialize(fields?: string[]): Partial<ApiUser> {
        const apiUser: ApiUser = {
            id: this.id,
            username: this.username,
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            is_active: this.isActive,
            is_staff: this.isStaff,
            is_superuser: this.isSuperuser
        };

        if (fields) {
            return pick(apiUser, fields) as Partial<ApiUser>;
        }

        return apiUser;
    }
}
export default User;
