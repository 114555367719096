import ApiAcheteur, { ApiAcheteurCreate, ApiAcheteurUpdate } from '@/apiClient/resources/acheteur/apiAcheteur';
import ApiClient from '@/apiClient/core/apiClient';
import ApiServiceDeserialized from '@/apiClient/resources/apiServiceDeserialized';
import Acheteur from '@/apiClient/resources/acheteur/acheteur';
import ListQuery from '@/apiClient/queryHelpers/listQuery';
import buildQueryConfig from '@/apiClient/queryHelpers/buildQueryConfig';

class AcheteurService
    extends ApiServiceDeserialized<
        Acheteur,
        ApiAcheteur,
        ApiAcheteurUpdate,
        ApiAcheteurCreate> {

    public static readonly ENDPOINTS = ['acheteurs'];

    constructor(client: ApiClient) {
        super(client, AcheteurService.ENDPOINTS, Acheteur.deserialize);
    }

    public loadCSV(listQ: ListQuery) {
        const queryConfig = buildQueryConfig(
            this.createUrlParts(undefined, this.ENDPOINTS),
            listQ,
            'csv');
        this.client.apiCore.get(
            `${process.env.VUE_APP_API_URL}/api/v1/${this.client.buildUrl(queryConfig)}`,
            {
                responseType: 'blob',
                headers: {
                    //'Accept': 'text/csv'
                }
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data as any]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'acheteurs.csv');
                document.body.appendChild(link);
                link.click();
            })
    }

}

export default AcheteurService;
