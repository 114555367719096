
import { Component, Vue, Prop } from 'vue-property-decorator';
import Acheteur from '@/apiClient/resources/acheteur/acheteur';
import Vendeur from '@/apiClient/resources/vendeur/vendeur';
import CallsList from '@/components/CallsList.vue';
import AddEditCallForm from '@/components/AddEditCallForm.vue';

@Component({
    components: {
        AddEditCallForm,
        CallsList,
    }
})
export default class CallsDialog extends Vue {

    @Prop({ default: 'vendeur' })
    public userType!: string;

    public dialogCallAddVisible = false;
    public dialogCallsListVisible = false;

    public selectedUser: Vendeur|Acheteur|null = null;

    public onSuccessCallCreate() {
        this.$emit('successCreate');
        this.dialogCallAddVisible = false;
        this.dialogCallsListVisible = false;
    }

    public addNewCall(user: Acheteur|Vendeur) {
        this.dialogCallsListVisible = false;
        this.selectedUser = user;
        this.dialogCallAddVisible = true;
    }

    public showCallsList(user: Acheteur|Vendeur) {
        this.selectedUser = user;
        this.dialogCallsListVisible = true;
    }

}
