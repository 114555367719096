
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import Acheteur from "@/apiClient/resources/acheteur/acheteur";
import Vendeur from "@/apiClient/resources/vendeur/vendeur";
import ListQuery from "@/apiClient/queryHelpers/listQuery";
import VendeurLineup from "@/apiClient/resources/vendeurLineup/vendeurLineup";

@Component({
    components: {
    }
})
export default class LineUpAcheteursMatching extends Vue {
    @Prop()
    public vendeurLineup!: VendeurLineup;

    get vendeur(): Vendeur {
        return this.vendeurLineup.vendeur;
    }

    get acheteurIdsValidated() {
        return this.vendeurLineup.acheteurs.map((ach) => (ach.id));
    }

    public dialogVisible = false;
    public acheteurs: Acheteur[] = [];
    public loading = false;

    public matchOn = {
        price: true,
        rendement: true,
        region: true,
    };

    @Watch('matchOn', {deep: true})
    public onMatchChange() {
        this.searchMatches();
    }

    public addToAcheteurs(acheteurId: string) {
        if (this.acheteurIdsValidated.includes(acheteurId)) {
            return;
        }
        const newAcheteursList = [
            ...this.acheteurIdsValidated,
            acheteurId,
        ];
        const payload = {
            acheteurs: newAcheteursList,
        };
        this.$api.vendeurLineups.patch(String(this.vendeurLineup.id), payload)
            .then(() => {
                this.$notify.success("Ajouté avec succès");
                this.$emit('change');
            })
            .catch((err) => {
                console.error(err);
                this.$notify.error("Une erreur est survenue");
            });
    }

    public searchMatches() {
        this.loading = true;
        this.dialogVisible = true;
        const vendeurEstate = this.vendeur.estate;

        const listQ = new ListQuery({
            pager: {
                limit: 25,
                offset: 0,
            }
        });
        if (this.matchOn.price && vendeurEstate.price) {
            listQ.filters.price = String(vendeurEstate.price);
        }
        if (this.matchOn.rendement && vendeurEstate.etatLocatif && vendeurEstate.price) {
            const rendement = 100 * vendeurEstate.etatLocatif / vendeurEstate.price;
            listQ.filters.rendement__lte = String(rendement);
            listQ.filters.rendement__gt = String(0);
        }
        this.$api.acheteurs.list(listQ)
            .then((resp) => {
                this.acheteurs = resp.results;
            })
            .catch((err) => {

            })
            .finally(() => {
                this.loading = false;
            });
    }
}
