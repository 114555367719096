
import { Component, Vue, Prop } from 'vue-property-decorator';
import Vendeur from '@/apiClient/resources/vendeur/vendeur';
import { ApiCallNoteCreate } from '@/apiClient/resources/callNote/apiCallNote';
import CallNote from '@/apiClient/resources/callNote/callNote';
import Acheteur from '@/apiClient/resources/acheteur/acheteur';

@Component({
    components: {
    }
})
export default class AddEditCallForm extends Vue {

    @Prop({ default: null })
    public user!: Vendeur|Acheteur;

    @Prop({ default: 'vendeur' })
    public userType!: string;

    // If edit: give it as prop
    @Prop({ default: null })
    public callNote!: CallNote;

    public hasTouchedAnswered = false;

    // create and update are the same
    public originalFormData: ApiCallNoteCreate = {
        object_id: this.user.id,
        content_type: this.userType === 'acheteur' ? 9 : 6,
        date: new Date().toISOString(),
        answered: false,
        notes: '',
    };

    public form: ApiCallNoteCreate = {
        ...this.originalFormData,
    };

    public created() {
        this.resetForm();
    }

    public resetForm() {
        this.hasTouchedAnswered = false;
        this.form = {
            ...this.originalFormData,
        };
        if (this.callNote) {
            this.form.date = this.callNote.date.toISOString();
            this.form.answered = this.callNote.answered;
            this.form.notes = this.callNote.notes;
        }
    }

    public onNotesChange() {
        if (this.hasTouchedAnswered) {
            return;
        }
        this.form.answered = !!this.form.notes;
    }

    public createEditCallNote() {
        const payload: ApiCallNoteCreate = {
            ...this.form,
        };

        if (payload.date) {
            const dateMidDay = new Date(payload.date);
            // dateMidDay.setHours(15, 0, 0);
            payload.date = dateMidDay.toISOString();
        }

        const request = (this.callNote ? this.$api.callNotes.patch : this.$api.callNotes.post).bind(this.$api.callNotes);
        const uuid = this.callNote ? [this.callNote.id.toString()] : [];

        request(uuid, payload)
            .then((callNoteUpdated) => {
                const word = this.callNote ? 'modifié' : 'créé';
                this.$notify.success('L\'appel a été ' + word + ' avec succès');
                this.$emit('success', callNoteUpdated);
            })
            .catch((err) => {
                console.error(err);
                // const errInfo = err.response?.data;
                this.$notify.error('Une erreur est survenue, merci de réessayer'); // TODO show errs.
            });
    }

}
