
import { Component, Vue, Prop } from 'vue-property-decorator';
import Acheteur from '@/apiClient/resources/acheteur/acheteur';

@Component({
    components: {
    }
})
export default class AcheteurTypeLabel extends Vue {

    @Prop({ default: null })
    public acheteur!: Acheteur;

    get background() {
        return this.acheteur.typeInfo?.background || 'white';
    }

    get color() {
        return this.acheteur.typeInfo?.color || '#555';
    }
}
