import AZImmoClient from '@/apiClient/client';
import { AxiosError } from 'axios';
import auth from '@/apiClient/auth';
import router from '@/router';

const apiSingleton = new AZImmoClient({
    root: process.env.VUE_APP_API_URL + '/api/v1'
});

// Intercept 401 errors due to token expired. Try to refresh it and re-request
const onRejected = (err: AxiosError) => {
    const firstMessage = (err?.response?.data?.messages || [])[0];

    const notLoggedInError = () => {
        auth.logout();
        router.push({
            name: 'login',
        }); // TODO: show a login or something?
    };

    if (err?.response?.status === 401) {
        if (firstMessage.token_type === 'access' &&
            (firstMessage.message === "Token 'exp' claim has expired" || firstMessage.message === 'Token is invalid or expired')) {
            return auth.refreshOldToken()
                .then((newToken) => {
                    err.config.headers.Authorization = 'Bearer ' + newToken;
                    return apiSingleton.apiCore.http.request(err.config);
                })
                .catch((err) => {
                    notLoggedInError();
                    throw err;
                });
        } else {
            notLoggedInError();
        }
    }

    return Promise.reject(err);
};

apiSingleton.apiCore.http.interceptors.response.use(undefined, onRejected);

export default apiSingleton;
