import ApiUser, { ApiUserCreate, ApiUserUpdate } from '@/apiClient/resources/user/apiUser';
import User from '@/apiClient/resources/user/user';
import ApiClient from '@/apiClient/core/apiClient';
import ApiServiceDeserialized from '@/apiClient/resources/apiServiceDeserialized';

class UserService
    extends ApiServiceDeserialized<
        User,
        ApiUser,
        ApiUserUpdate,
        ApiUserCreate> {

    public static readonly ENDPOINTS = ['users'];

    constructor(client: ApiClient) {
        super(client, UserService.ENDPOINTS, User.deserialize);
    }

    public getMe() {
        return this.get('me');
    }

    public createPasswordReset(userId: number) {
        return this.custom<void, ApiUser>({
            method: 'POST',
            customMethod: 'create_password_reset',
            uuids: userId.toString(),
        }).then(User.deserialize);
    }

    public resetPassword(userId: string, payload: {token: string; password: string}) {
        return this.custom<{ token: string; password: string }, ApiUser>({
            method: 'POST',
            customMethod: 'reset_password',
            uuids: userId.toString(),
            data: payload,
        }).then(User.deserialize);
    }

}

export default UserService;
