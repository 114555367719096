import ApiClient from '@/apiClient/core/apiClient';
import ApiServiceDeserialized from '@/apiClient/resources/apiServiceDeserialized';
import CallNote from './callNote';
import ApiCallNote, { ApiCallNoteCount, ApiCallNoteCountResp, ApiCallNoteCreate, ApiCallNoteUpdate } from './apiCallNote';
import ListQuery from '@/apiClient/queryHelpers/listQuery';

class CallNoteService
    extends ApiServiceDeserialized<
        CallNote,
        ApiCallNote,
        ApiCallNoteUpdate,
        ApiCallNoteCreate|ApiCallNoteCreate[]> {

    public static readonly ENDPOINTS = ['call-notes'];

    constructor(client: ApiClient) {
        super(client, CallNoteService.ENDPOINTS, CallNote.deserialize);
    }

    public count(fromDate: Date, toDate: Date, aggregation: string, content_type?: number, answered?: boolean) {
        const listQ = new ListQuery({
            pager: {
                limit: 2000,
                offset: 0,
            },
            filters: {
                from: fromDate.toISOString(),
                to: toDate.toISOString(),
                aggregation,
            },
        });
        if (typeof content_type !== 'undefined') {
            listQ.filters.content_type = content_type.toString();
        }
        if (typeof answered !== 'undefined') {
            listQ.filters.answered = answered ? 'True' : 'False';
        }
        return this.custom<any, ApiCallNoteCountResp>({
            data: undefined,
            uuids: [],
            method: 'get',
            customMethod: 'count',
            listQuery: listQ,
        });
    }
}

export default CallNoteService;
