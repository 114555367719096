
import { Component, Vue, Prop } from 'vue-property-decorator';
import Acheteur from "@/apiClient/resources/acheteur/acheteur";
import CreateUpdateAcheteurForm from "@/components/CreateUpdateAcheteurForm.vue";

@Component({
    components: {
        CreateUpdateAcheteurForm,
    }
})
export default class LineupAcheteursList extends Vue {
    @Prop({default: () => ([])})
    public acheteurs!: Acheteur[];

    public selectedAcheteur: Acheteur|null = null;
    public dialogEditVisible = false;

    public showDetails = false;

    public onSuccessUpdate() {
        this.dialogEditVisible = false;
        this.selectedAcheteur = null;
        this.$emit('change');
    }

    public selectAcheteur(ach: Acheteur) {
        this.selectedAcheteur = ach;
        this.dialogEditVisible = true;
    }

}
