import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import NotFound from '../views/NotFound.vue';
import Login from '../views/Login.vue';
import { NavigationGuardNext, Route } from 'vue-router/types/router';
import auth from '@/apiClient/auth';
import store from '@/store';
import apiSingleton from '@/apiClient/apiSingleton';
import Vendeurs from '@/views/Vendeurs.vue';
import Acheteurs from '@/views/Acheteurs.vue';
import ImportCsvVendeurs from '@/views/ImportCsvVendeurs.vue';
import ImportCsvAcheteurs from '@/views/ImportCsvAcheteurs.vue';
import Statistics from '@/views/Statistics.vue';
import Lineup from '@/views/Lineup.vue';

Vue.use(VueRouter);

const verifLogin = (to: Route, from: Route, next: NavigationGuardNext) => {
    if (store.state.user) {
        next();
    } else {
        if (!auth.token) {
            next({
                name: 'login',
                query: {
                    next: to.fullPath,
                },
            });
            return;
        }
        // Has a token but user not loaded yet
        apiSingleton.users.getMe()
            .then((user) => {
                store.commit('setUserSelf', user);
                next();
            })
            .catch((err: any) => {
                console.error(err);
                next({
                    name: 'login',
                    query: {
                        next: to.fullPath,
                    },
                });
            });
    }
};

const routes: Array<RouteConfig> = [
    {
        path: '/',
        redirect: '/vendeurs',
        beforeEnter: verifLogin,
    },
    {
        path: '/vendeurs',
        name: 'vendeurs',
        component: Vendeurs,
        beforeEnter: verifLogin,
    },
    {
        path: '/acheteurs',
        name: 'acheteurs',
        component: Acheteurs,
        beforeEnter: verifLogin,
    },
    {
        path: '/lineup',
        name: 'lineup',
        component: Lineup,
        beforeEnter: verifLogin,
    },
    {
        path: '/importer-vendeurs',
        name: 'import-vendeurs',
        component: ImportCsvVendeurs,
        beforeEnter: verifLogin,
    },
    {
        path: '/importer-acheteurs',
        name: 'import-acheteurs',
        component: ImportCsvAcheteurs,
        beforeEnter: verifLogin,
    },
    {
        path: '/stats',
        name: 'stats',
        component: Statistics,
        beforeEnter: verifLogin,
    },
    /* {
        path: '/todo',
        name: 'todo',
        component: Todo,
        beforeEnter: verifLogin,
    },*/
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    /*{
        path: '/portail',
        name: 'portal',
        beforeEnter: (to, from, next) => {
            verifLogin(to, from, (routeWanted) => {
                if (routeWanted) {
                    next(routeWanted);
                    return;
                }
                if (store.getters.isAdmin) {
                    return next({
                        name: 'admin-patients-list',
                    });
                } else {
                    return next({
                        name: 'consultations-list',
                    });
                }
            });
        }
    },*/
    {
        path: '/notfound',
        name: 'notfound',
        component: NotFound,
    },
    { path: '*', component: NotFound }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
