import pick from '@/apiClient/queryHelpers/pick';
import ApiAcheteur from '@/apiClient/resources/acheteur/apiAcheteur';
import { ContactInfo } from '@/apiClient/resources/shared/vendeurAcheteur';
import AcheteurTypes from '@/modules/acheteurTypes';
import CallNote from '@/apiClient/resources/callNote/callNote';
import ApiCallNote from '@/apiClient/resources/callNote/apiCallNote';
import telNumbersToLinks from '@/modules/telNumbersToLinks';
import { nextCallFormatted } from '@/modules/dateFormatter';
import Vendeur from '@/apiClient/resources/vendeur/vendeur';
import ApiVendeur from '@/apiClient/resources/vendeur/apiVendeur';

interface EstateInfo {
    region: string|null;
    nbFlats: number|null;
    yield: number|null;
    priceMin: number|null;
    priceMax: number|null;
}

class Acheteur {
    public id!: string;
    public contact!: ContactInfo;
    public estate!: EstateInfo;
    public nextCall!: string;
    public nextAction!: string;
    public notes!: string;
    public type!: string;

    public calls: CallNote[] = [];
    public errors = '';

    public linked: Vendeur|null = null;

    constructor(apiAcheteur: ApiAcheteur) {
        this.id = apiAcheteur.id;
        this.type = apiAcheteur.type;
        this.errors = apiAcheteur.errors;
        this.contact = {
            companyName: apiAcheteur.company_name || '',
            address: apiAcheteur.address || '',
            address2: apiAcheteur.address2 || '',
            email: apiAcheteur.email || '',
            fullName: apiAcheteur.full_name || '',
            telephone: apiAcheteur.telephone || ''
        };
        this.estate = {
            region: apiAcheteur.estate_region || '',
            nbFlats: apiAcheteur.estate_nb_flats,
            yield: apiAcheteur.estate_yield,
            priceMin: apiAcheteur.estate_price_min,
            priceMax: apiAcheteur.estate_price_max,
        };
        this.nextCall = apiAcheteur.next_call;
        this.nextAction = apiAcheteur.next_action;
        this.notes = apiAcheteur.notes;

        this.calls = (apiAcheteur.calls?.map(CallNote.deserialize) || []).sort((a, b) => {
            return a.date > b.date ? -1 : 1;
        });

        if (apiAcheteur.linked) {
            this.linked = Vendeur.deserialize(apiAcheteur.linked);
        }
    }

    get telephoneLinks() {
        return telNumbersToLinks(this.contact.telephone || '');
    }

    get nextCallDate() {
        if (!this.nextCall) {
            return null;
        }
        const d = new Date(this.nextCall);
        if (!isNaN(d as any)) {
            return d;
        }
        return null;
    }

    get nextCallLabel() {
        return nextCallFormatted(this.nextCallDate);
    }

    get todaysCall() {
        const today = new Date();
        const firstCall = this.calls[0];
        if (firstCall && firstCall.date.toISOString().slice(0, 10) === today.toISOString().slice(0, 10)) {
            return firstCall;
        }
        return null;
    }

    get typeInfo() {
        const foundInfo = Object.keys(AcheteurTypes).find((key) => {
            return AcheteurTypes[key].key === this.type;
        });
        if (foundInfo) {
            return AcheteurTypes[foundInfo];
        }
        return null;
    }

    get typeLabel() {
        if (this.typeInfo) {
            return this.typeInfo.label;
        }
        return this.type;
    }

    public static deserialize(apiAcheteur: ApiAcheteur) {
        return new Acheteur(apiAcheteur);
    }

    public serialize(fields?: string[]): Partial<ApiAcheteur> {
        const apiAcheteur: ApiAcheteur = {
            id: this.id,
            company_name: this.contact.companyName,
            full_name: this.contact.fullName,
            telephone: this.contact.telephone,
            email: this.contact.email,
            address: this.contact.address,
            address2: this.contact.address2,

            type: this.type,

            errors: this.errors,

            estate_region: this.estate.region,
            estate_nb_flats: this.estate.nbFlats,
            estate_yield: this.estate.yield,
            estate_price_min: this.estate.priceMin,
            estate_price_max: this.estate.priceMax,

            next_call: this.nextCall,
            next_action: this.nextAction,
            notes: this.notes,

            calls: this.calls.map((c) => (c.serialize() as ApiCallNote)) || [],

            linked: this.linked ? this.linked?.serialize() as ApiVendeur : null,
        };

        if (fields) {
            return pick(apiAcheteur, fields) as Partial<ApiAcheteur>;
        }

        return apiAcheteur;
    }
}
export default Acheteur;
