import pick from '@/apiClient/queryHelpers/pick';
import ApiCallNote from '@/apiClient/resources/callNote/apiCallNote';

class CallNote {
    public id!: number;
    public objectId!: string; // Foreign key
    public contentType!: number;
    public date!: Date;
    public answered = false;
    public notes = '';

    constructor(apiCallNote: ApiCallNote) {
        this.id = apiCallNote.id;
        this.objectId = apiCallNote.object_id;
        this.contentType = apiCallNote.content_type;
        this.date = new Date(apiCallNote.date);
        this.answered = apiCallNote.answered;
        this.notes = apiCallNote.notes;
    }

    public static deserialize(apiCallNote: ApiCallNote) {
        return new CallNote(apiCallNote);
    }

    public serialize(fields?: string[]): Partial<ApiCallNote> {
        const apiCallNote: ApiCallNote = {
            id: this.id,
            object_id: this.objectId,
            content_type: this.contentType,
            date: this.date.toISOString(),
            answered: this.answered,
            notes: this.notes,
        };

        if (fields) {
            return pick(apiCallNote, fields) as Partial<ApiCallNote>;
        }

        return apiCallNote;
    }

    get dateString() {
        return this.date.toISOString().slice(0, 10).split('-').reverse().join('.');
    }
}
export default CallNote;
